import React from 'react'
import './styles.scss'
import { BarChart } from '../BarChart/BarChart'

const OptiWeighDistribution = ({ data }) => {
  return (
    <div className='lot-documents'>
      <div className='row'>
        <div className='row-title-wrap'>
          <p className='row-title'>Optiweigh - Weight Distribution</p>
        </div>
        <div className={'bordered-container'}>
          <div className='optiweigh-container'>
            <BarChart individualAssessmentData={data} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default OptiWeighDistribution
