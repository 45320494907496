import React, { PureComponent } from 'react'
import queryString from 'query-string';
import PropTypes from 'prop-types'
import { Link, NavLink } from 'react-router-dom'
import { WanderingCubes } from 'better-react-spinkit'
import update from 'immutability-helper'
import axios from 'axios'
import memoize from 'memoize-one'
import { WEB_APP } from '../../../config/index'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faTimes
} from '@fortawesome/fontawesome-free-solid'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import _history from '../../../history'
import { withRouter } from "react-router-dom"
import Api from '../../../services/api'
import InfiniteScroll from 'react-infinite-scroll-component'
import FilterKind from '../../FilterKind/FilterKind'
import FilterMultiSelect from '../../FilterMultiSelect/FilterMultiSelect'
import { subTitles } from '../../../constants'
import { momentTz, limitTextLength, calculateLimitTextLength } from '../../../utils'
import { getSectionHeaderTitle } from '../../../utils/Auctions'
import { filterAuctions } from '../../../utils/Filter'
import { updateInitialData, updateMainData } from '../../../utils/UpdateData'
//import {  } from "react-router-dom";
import bookImage from '../../../assets/img/book.png'
import cowImage from '../../../assets/img/cowauction.png'
import lambImage from '../../../assets/img/lambauction.png'
import goatImage from '../../../assets/img/goat-image.png'
import farmgateimage from '../../../assets/img/poweredByFarmgate.png'
//const history = useHistory()


class AuctionsList extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      page: 1,
      limit: 10,
      hasMore: true,
      fetching: false,
      auctions: [],
      filters: {
        screen: this.getScreen(),
        showMode: this.getSavedFilterValue('showMode', 'all'),
        state: this.getSavedFilterValue('state', [])
      }
    }
    this.cancelSource = axios.CancelToken.source()
    this.getAuctions = this.getAuctions.bind(this)
    this.renderItem = this.renderItem.bind(this)
    this.getFilteredAuctions = memoize(this.getFilteredAuctions)
    this.changeShowMode = this.changeShowMode.bind(this)
    this.filterChangeHandler = this.filterChangeHandler.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
  }
  componentDidMount() {
    window.scroll(0, 0)
    this.getAuctions()
    updateInitialData()
    updateMainData()
    window.addEventListener('message', message => {
      if (message && message.data && message.data.match && message.data.match('Get iframe height')) {
        let height = document.getElementsByClassName('app-route')[0].offsetHeight
        window.parent.parent.postMessage(`iframe height=${height}`, '*')
      }
    })
  }
  componentWillUnmount() {
    this.cancelSource.cancel()
    window.removeEventListener('message', message => {
      if (message && message.data && message.data.match && message.data.match('Get iframe height')) {
        let height = document.getElementsByClassName('app-route')[0].offsetHeight
        window.parent.parent.postMessage(`iframe height=${height}`, '*')
      }
    })
  }
  componentDidUpdate(prevProps, prevState) {
    let scrollPosition = parseInt(window.localStorage.getItem('savedResultsAuctionsScrollPosition')) || 0
    let countOfAuctions = parseInt(window.localStorage.getItem('savedResultCountOfAuctions')) || 0
    if (countOfAuctions !== 0 && scrollPosition !== 0) {
      window.scrollTo(0, scrollPosition)
      if (this.state.auctions.length === countOfAuctions) {
        window.localStorage.clear()
      }
    }
    if (this.state.filters !== prevState.filters) {
      this.props.mergeStateProp('filters', [this.state.filters], 'user')
    }
  }
  getScreen() {
    return 'resultsAuctionsList'
  }
  getSavedFilterValue(key, defaultValue = []) {
    let screen = this.getScreen()
    let thisFilter = this.props.filters.find(filterSet => filterSet.screen === screen)
    let value
    if (thisFilter) {
      value = thisFilter[key]
    }
    return value || defaultValue
  }
  getAuctions() {
    let sortType = 'liveAt'
    let sortDirection = -1
    this.setState({
      fetching: true,
      hasMore: false
    })
    Api.getResults(this.state.page, this.state.limit, sortType, sortDirection, { cancelToken: this.cancelSource.token })
      .then(data => {
        if (data && !data.cancel) {
          let newAuctions = data.auctions.docs
          this.setState({
            auctions: this.state.auctions.length ? this.state.auctions.concat(newAuctions) : newAuctions,
            page: this.state.page + 1,
            hasMore: parseInt(data.auctions.page, 10) < data.auctions.pages,
            fetching: false
          })
        }
      })
  }
  resetState() {
    this.setState({
      page: 1,
      auctions: []
    }, () => { this.getAuctions() })
  }
  getAuctionStatus(auction) {
    let status
    switch (auction.state) {
      case 'closed':
        status = 'Closed'
        break
      case 'open':
        status = 'Open'
        break
      case 'live':
        status = 'Live'
        break
      case 'future':
      default:
        status = 'Upcoming'
        break
    }
    return status
  }
  getAuctionState(auction) {
    return auction.location.state.join('/')
  }
  getAuctionType(auction) {
    switch (auction.auctionType) {
      case 'simultaneous':
        return 'SIM'
      case 'sequential':
        return 'SEQ'
      default:
        return ''
    }
  }
  getFilteredAuctions(auctions, filters) {
    let filteredAuctions = auctions.filter(auction => {
      return filters.showMode === 'all'
        ? true
        : auction.kindData.type === filters.showMode
    })

    return filterAuctions(filteredAuctions, filters)
  }
  clearFilter() {
    this.setState(update(this.state, {
      filters: {
        showMode: { $set: 'all' },
        state: { $set: [] }
      }
    }))
  }
  filterChangeHandler(filterName, value) {
    this.setState({
      filters: update(this.state.filters, {
        [filterName]: { $set: value }
      })
    })
  }
  changeShowMode(showMode) {
    this.setState({
      filters: update(this.state.filters, {
        showMode: { $set: showMode }
      })
    })
  }
  goToLink(auction) {
    _history.push(`/main/results/${auction.kindData.title}/${auction.searchNumber}`)
  }

  redirectOnWebApp(auction) {
    window.parent.parent.postMessage(`Redirect for url=${WEB_APP}/#/main/results/${auction.kindData.title}/${auction.searchNumber}?AucRes=${auction.kindData.title}/${auction.searchNumber}`, '*')
    window.open(
      `${WEB_APP}/#/main/results/${auction.kindData.title}/${auction.searchNumber}?AucRes=${auction.kindData.title}/${auction.searchNumber}`
    );
  }

  groupAuctionsByDate(auctions) {
    // assume auctions already sorted by date
    let groupedAuctions = auctions.reduce((r, auction) => {
      let title = getSectionHeaderTitle(auction.liveAt)
      let group = r.find(gr => gr.title === title)
      if (!group) {
        group = { title: title, auctions: [] }
        r.push(group)
      }
      group.auctions.push(auction)
      return r
    }, [])

    return groupedAuctions
  }
  renderItem(auction) {
    let params = queryString.parse(window.location.search)
    let clienTag = params.client ? params.client : ''
    return (
      <div className='auction-item' key={auction._id}>
        <div className='auction-img-container'>
          {
            auction.image ? (
              <img className='auction-img' src={auction.image.url} alt='Auction img' />
            ) : (
              <span className={`no-image ${auction.kindData.type}`} alt='Lot'>No image</span>
            )
          }
        </div>
        <div className='auction-info'>
          <div className='auction-info-top'>
            <div>
              <h3 className='auction-title'>{auction.title}</h3>
              {auction.state === 'open' && <p className='auction-live-info'>Auction is open</p>}
              {auction.state === 'live' && <p className='auction-live-info'>Auction is live</p>}
              {auction.state !== 'live' && auction.state !== 'open' && (
                <div>
                  <p className='auction-live-date'>{momentTz(auction.liveAt).format('dddd D MMMM YYYY - h.mm A')}</p>
                </div>
              )}
            </div>
            <div className='info-btns'>
              {clienTag === 'farmbuy' ? (
                <Link className='btn' to={`/main/results/${auction.kindData.title}/${auction.searchNumber}`} onClick={() => {
                  window.localStorage.setItem('savedResultsAuctionsScrollPosition', window.scrollY)
                  window.localStorage.setItem('savedResultCountOfAuctions', this.state.auctions.length)
                  window.localStorage.removeItem('savedResultsScrollPosition')
                  window.localStorage.removeItem('savedResultCountOfLots')
                }}>
                  <span>View results</span>
                  <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
                </Link>
              ) : (
                <NavLink className='btn' onClick={() => {
                  this.redirectOnWebApp(auction)
                }}>
                  <span>Login for results</span>
                  <span className='icon-wrapper icon-warning'><img src={bookImage} alt='Book icon' /></span>
                </NavLink>

              )

              }

            </div>
          </div>
          <div className='auction-info-middle'>
            <div className='auction-type'>
              {auction.kindData.type === 'cattle' && <img src={cowImage} alt='Cattle' />}
              {auction.kindData.type === 'sheep' && <img src={lambImage} alt='Sheep' className='icon-blackened' />}
              {auction.kindData.type === 'goat' && <img src={goatImage} alt='Goat' />}
            </div>
            <div className='auction-desc'>
              {limitTextLength(auction.descriptionShort, calculateLimitTextLength(auction.descriptionShort, 150), false)}
              <span className='auction-readMore' onClick={() => {
                this.props.history.push(this.goToLink(auction))
                window.localStorage.setItem('savedResultsAuctionsScrollPosition', window.scrollY)
                window.localStorage.setItem('savedResultCountOfAuctions', this.state.auctions.length)
                window.localStorage.removeItem('savedResultsScrollPosition')
                window.localStorage.removeItem('savedResultCountOfLots')
              }}>
                {(auction.descriptionShort && (auction.descriptionShort.length > calculateLimitTextLength(auction.descriptionShort, 150) || auction.descriptionShort.split(/\r\n|\r|\n/g).length >= 4)) ? '...Read More' : ''}
              </span>
            </div>
            <div className='auction-logo'>
              {auction.logo && <img src={auction.logo.url} alt='Auction Logo' />}
            </div>
          </div>
          <div className='auction-info-bottom'>
            <span className='auction-number'>Auction No. {auction.number}</span>
            <span className='status'>Status: {this.getAuctionStatus(auction)}</span>
            <span className='location-state'>{this.getAuctionState(auction)}</span>
            <span className='heads'>{auction.headCount} Head</span>
            <span className='lots'>{auction.lotCount} Lots</span>
          </div>
        </div>
      </div>
    )
  }
  renderLockedItem(auction) {
    return (
      <div className='locked-item' key={auction._id}>
        <span className='result'>cancelled</span>
        {this.renderItem(auction)}
      </div>
    )
  }
  getSite(clienTag) {
    if (window.location.host.includes('test-site')) {
      return `https://test-webapp.farmgatetest.com.au/#/sign-up?wp&&${clienTag}`
    } else if (window.location.host.includes('localhost')) {
      return `http://localhost:4000/#/sign-up?wp&&${clienTag}`
    } else {
      return `https://live.farmgateauctions.com.au/#/sign-up?wp&&${clienTag}`
    }
  }
  render() {
    const { locationStates } = this.props
    const { filters, hasMore, auctions } = this.state
    const { showMode, state } = filters
    let filteredAuctions = this.getFilteredAuctions(auctions, filters)
    let clienTag = window.location.href.split('?client=')[1] ? window.location.href.split('?client=')[1] : 'Farmgate'
    const site = this.getSite(clienTag)
    return (
      <div className='auctions-wrapper'>
        <div className="logo-reg-link" >
          <span className='icon'><img className="img" src={farmgateimage} alt='Farmgate icon' /></span></div>
        <div className="top-line" >
          <span className="span-text" >New to FarmGate?</span>
          <a className="sign-uplink" href={`${site}`} rel="noopener noreferrer" target="_blank" >Click here to Register</a>
        </div>
        <p className='fg-paragraph'>{subTitles.UPCOMING_RESULTS}</p>
        <div className='auctions-controls'>
          <h3 className='filters-heading'>Filters</h3>
          <div className='auctions-filters-wrapper'>
            <FilterKind changeCategoryChoice={this.changeShowMode} categoryChoice={showMode} />
            <FilterMultiSelect
              title='State'
              name='state'
              options={locationStates}
              selectedOptions={state}
              onChange={this.filterChangeHandler}
              icon={<FontAwesomeIcon icon={faCaretDown} size='lg' />}
              buttonLg
            />
            <div
              className='btn filter-btn filter-btn-sm clear-all-btn'
              onClick={this.clearFilter}
            >
              <span>Clear All</span>
              <span className='icon-wrapper icon-danger'><FontAwesomeIcon icon={faTimes} /></span>
            </div>
          </div>
        </div>
        <InfiniteScroll
          next={this.getAuctions}
          hasMore={hasMore}
          scrollThreshold={0.8}
          dataLength={auctions.length}
          style={{ overflow: 'initial' }}>
          <div className='auctions-list list'>
            {this.groupAuctionsByDate(filteredAuctions).map(group => {
              return (
                <section className='auctions-group' key={group.title}>
                  <p className='group-title'>{group.title}</p>
                  {group.auctions.map(auction => {
                    if (auction.cancelled) {
                      return this.renderLockedItem(auction)
                    } else {
                      return this.renderItem(auction)
                    }
                  })}
                </section>
              )
            })}
            {
              (!this.state.fetching && !this.state.auctions.length) || !filteredAuctions.length
                ? (
                  <div className='auctions-empty'>
                    <FontAwesomeIcon icon={faClock} />
                    <p>Coming Soon</p>
                  </div>
                )
                : null
            }

          </div>
          <div className={`scroll-loader-wrap ${this.state.fetching ? 'active' : ''}`}>
            <WanderingCubes className='scroll-loader' color={'gray'} />
          </div>
        </InfiniteScroll>
      </div>
    )
  }
}

AuctionsList.propTypes = {
  changeStateProp: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}
AuctionsList.defaultProps = {
}

export default withRouter(AuctionsList)
