export const EMAIL_REGEXP = /(([\w-]+\.)+[\w-]+|([a-zA-Z]{1}|[\w-]{2,}))@((([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])\\.([0-1]?[0-9]{1,2}|25[0-5]|2[0-4][0-9])){1}|([a-zA-Z][\w-]+\.)+[a-zA-Z]{2,4})/
export const PASSWORD_REGEXP = /^[0-9a-zA-Z@%+/’!#$^?:,(){}[\]~_-]+$/
export const FIRST_LAST_NAME = /^[&0-9a-zA-Z \-'"’”]+$/
export const NUMBER_REGEXP = /^\d+$/
export const PHONE_REGEXP = /[0-9]{10}/
export const PHONE_SECOND_REGEXP = /\(?([0-9]{4})\)?([ .-]?)([0-9]{4})/
export const WEBSITE_REGEXP = /(?:(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])|(?:biz|b[abdefghijmnorstvwyz])|(?:cat|com|coop|c[acdfghiklmnoruvxyz])|d[ejkmoz]|(?:edu|e[cegrstu])|f[ijkmor]|(?:gov|g[abdefghilmnpqrstuwy])|h[kmnrtu]|(?:info|int|i[delmnoqrst])|(?:jobs|j[emop])|k[eghimnprwyz]|l[abcikrstuvy]|(?:mil|mobi|museum|m[acdeghklmnopqrstuvwxyz])|(?:name|net|n[acefgilopruz])|(?:org|om)|(?:pro|p[aefghklmnrstwy])|qa|r[eosuw]|s[abcdeghijklmnortuvyz]|(?:tel|travel|t[cdfghjklmnoprtvwz])|u[agksyz]|v[aceginu]|w[fs]|(?:δοκιμή|испытание|рф|срб|טעסט|آزمایشی|إختبار|الاردن|الجزائر|السعودية|المغرب|امارات|بھارت|تونس|سورية|فلسطين|قطر|مصر|परीक्षा|भारत|ভারত|ਭਾਰਤ|ભારત|இந்தியா|இலங்கை|சிங்கப்பூர்|பரிட்சை|భారత్|ලංකා|ไทย|テスト|中国|中國|台湾|台灣|新加坡|测试|測試|香港|테스트|한국|xn\\-\\-0zwm56d|xn\\-\\-11b5bs3a9aj6g|xn\\-\\-3e0b707e|xn\\-\\-45brj9c|xn\\-\\-80akhbyknj4f|xn\\-\\-90a3ac|xn\\-\\-9t4b11yi5a|xn\\-\\-clchc0ea0b2g2a9gcd|xn\\-\\-deba0ad|xn\\-\\-fiqs8s|xn\\-\\-fiqz9s|xn\\-\\-fpcrj9c3d|xn\\-\\-fzc2c9e2c|xn\\-\\-g6w251d|xn\\-\\-gecrj9c|xn\\-\\-h2brj9c|xn\\-\\-hgbk6aj7f53bba|xn\\-\\-hlcj6aya9esc7a|xn\\-\\-j6w193g|xn\\-\\-jxalpdlp|xn\\-\\-kgbechtv|xn\\-\\-kprw13d|xn\\-\\-kpry57d|xn\\-\\-lgbbat1ad8j|xn\\-\\-mgbaam7a8h|xn\\-\\-mgbayh7gpa|xn\\-\\-mgbbh1a71e|xn\\-\\-mgbc0a9azcg|xn\\-\\-mgberp4a5d4ar|xn\\-\\-o3cw4h|xn\\-\\-ogbpf8fl|xn\\-\\-p1ai|xn\\-\\-pgbs0dh|xn\\-\\-s9brj9c|xn\\-\\-wgbh1c|xn\\-\\-wgbl6a|xn\\-\\-xkc2al3hye2a|xn\\-\\-xkc2dl3a5ee0h|xn\\-\\-yfro4i67o|xn\\-\\-ygbi2ammx|xn\\-\\-zckzah|xxx)|y[et]|z[amw])/
export const AUSTRALIAN_POSTCODE = /^\d{4}$/
export const AUSTRALIAN_POSTCODE_SOFT = /^\d{0,4}$/
export const PIC_REGEXP = /^[0-9A-Z]{8}$/
export const ADJUST_WEIGHT_RANGE = { max: 20, min: -20 }
export const NUMBER_REGEXP_WITH_MINUS = /^[-+]?\d*$/

export const NSW_REGEXP = /^N[A-K]{1}[0-9]{6}$/
export const QLD_REGEXP = /^Q[A-Z]{3}[0-9]{4}$/
export const VIC_REGEXP = /^3[A-Z]{4}[0-9]{3}$/
export const WA_REGEXP = /^W[A-Z]{3}[0-9]{4}$/
export const SA_REGEXP = /^S[ABCK]{1}[0-9]{6}$/
export const NT_REGEXP = /^T[A-Z]{3}[0-9]{4}$/
export const TAS_REGEXP = /^M[A-Z]{3}[0-9]{4}$/

export const PIC_REGEXP_SOFT = /^[0-9A-Z]{0,8}$/
export const ABN_REGEXP = /^\d{11}$/
export const ABN_REGEXP_SOFT = /^\d{0,11}$/
export const ADDRESS_REGEXP = /^[0-9a-zA-Z \-'"’”]+$/
export const FLOAT_REGEXP = /^[0-9]*\.?[0-9]*$/
export const INTEGER_REGEXP = /^[0-9]*$/
// Redux
export const CHANGE_STATE_PROP = '_CHANGE_STATE_PROP'
export const MERGE_STATE_PROP = '_MERGE_STATE_PROP'
export const FILTER_STATE_PROP = '_FILTER_STATE_PROP'
export const TEMP_RESTORE_DRAFT_LOT = 'TEMP_RESTORE_DRAFT_LOT'
export const USER_LOGOUT = 'USER_LOGOUT'
export const SHOW_SPINNER = 'SHOW_SPINNER'
export const SHOW_MODAL = 'SHOW_MODAL'
export const HIDE_MODAL = 'HIDE_MODAL'
export const CLOSE_USER_NOTIFICATION = 'CLOSE_USER_NOTIFICATION'
export const SHOW_SINGLE_NOTIFICATION = 'SHOW_SINGLE_NOTIFICATION'
export const HIDE_SINGLE_NOTIFICATION = 'HIDE_SINGLE_NOTIFICATION'
export const CLOSE_SINGLE_NOTIFICATION = 'CLOSE_SINGLE_NOTIFICATION'
export const UPDATE_LOT_INFO = 'UPDATE_LOT_INFO'
export const MAX_HEADS = 20000
export const MAX_WEIGHT = 10000000
export const MAX_VENDORBIDS = 1000000
export const MIN_WEIGHED_HEADS_PERCENTAGE = 10
export const bidTypes = {
  HEAD: 'head',
  KG: 'kg'
}
export const subTitles = {
  UPCOMING_AUCTIONS: 'Australia wide Cattle and Sheep auctions. Filter by stock type, view the Catalogue, enter Live Auctions or easily add your Lot for sale by clicking the Add Lot button.',
  UPCOMING_AUCTIONS_BUYERS: 'Australia wide Cattle and Sheep auctions. Filter by stock type, enter Live Auctions and download, email or print the Catalogue from your iPhone, iPad and desktop.',
  WATCH_LIST: 'Click the blue tags on Lots you are interested in bidding on to add them to your Watch List and track them easily in the Catalogue and Live Auction.',
  UPCOMING_RESULTS: 'Australia wide Cattle and Sheep auction results. View sold, passed or no bid Lots from the entire Auction.',
  MY_ADMIN: 'Keep track of your submitted lots, edit saved lots, view your personal sale results, save your favourite PICs, reoffer passed in or no bid listings and more.',
  MY_ADMIN_BUYERS: 'View your personal sale results and keep your profile up to date.',
  CONTACT_US: 'We’re here to help whenever you need.',
  SUBMITTED_LOTS: 'View your pending and submitted Lots for Upcoming Auctions.',
  SAVED_LOTS: 'Finish editing Lots you have saved and submit them to Auction.',
  MY_AUCTION_RESULTS: 'A quick and easy way to view your personal Auction Results. Filter by sold, passed in, no bid and purchased Lots.',
  MY_AUCTION_RESULTS_BUYERS: 'A quick and easy way to view the Lots you have purchased.',
  MY_REOFFERS: 'Reoffer passed in or no bid Lots without charge. You have 7-days to reoffer cattle, sheep and lambs.',
  MY_PROFILE: 'Keep your business details up to date. Upload your company logo to help your Lots stand out in the Catalogue.',
  MY_PROFILE_BUYERS: 'Keep your business details up to date and add additional PIC numbers.',
  MY_FAVOURITE_PICS: 'Save the details of vendors you work with regularly and simply select them when filling out the Assessment Form. Their information will auto-populate.',
  TOOLS: 'Print Cattle & Sheep Assessment Forms to fill in on property, check out our Getting Started, FAQs and How-To Videos & Tips to best use the platform.',
  BUYER_TOOLS: 'Check out our Getting Started, FAQs and How-To Videos & Tips to best use the platform.',
  UPLOAD_CSV: `A Vendor or Vendor's Agent has the right to bid on behalf of the Vendor for their own Lot through our Auction System, only where such Vendor's bid(s) is/are publicly disclosed on our website such as via the Lot Assessement form. Such a notification, forms part of the conditions of sale. The Vendor or Vendor's Agent is restricted to the number of bids noted on this Lot Assessment form.`
}
export const apiHeaders = {
  LOT_PAGE_VIEW: 'farmgate-lot-page-view'
}
export const AUCTION_ADD_LIMIT = 10 // days
export const AUCTION_REOFFER_LIMIT = 7 // days
export const LOT_TIMER_ENDING_THRESHOLD = 1000 * 60 // ms
export const FORM_ABBREVIATIONS = {
  '4+ Teeth': '4+T',
  'Calves': 'Cal',
  'Commercial': 'Com',
  'Hoggets': 'Hog',
  'Lambs': 'Lamb',
  'NSM': 'NSM',
  'PTE': 'PTE',
  'PTIC': 'PTIC',
  'Scanned in Lamb': 'SIL',
  'Scanned not in Lamb': 'SNIL',
  'Stud': 'Stud',
  'Vealers': 'Veal',
  'Weaners': 'Wean',
  'Yearlings': 'Year'
}
export const WEANING_DETAILS_EXCEPTIONS = ['Calves', 'Vealers', 'Weaners', 'Yearlings', 'Lambs']
export const LOT_HIDDEN_FIELDS = ['district', 'animalIndividualId', 'animalTeeth', 'animalHorn', 'animalBlemishes']
