import React, { Component } from 'react'
import { makePrice } from '../../utils/Lots'
import { bidTypes } from '../../constants'

export default class LotsListClosedStatus extends Component {
  getLotClosedStatus (lot) {
    let state
    switch (lot.status) {
      case 'noBid':
        state = 'No Bids'
        break
      case 'passed':
        state = 'Passed In'
        break
      case 'sold':
        state = 'Sold'
        break
      case 'soldAfterAuction':
        state = 'Sold AA'
        break
      default:
        state = ''
    }
    if (state) {
      let priceKg = makePrice(lot.closedPrice / lot.publicDetails.weight.curfew, {
        bidding: 'kg',
        precision: 0
      })
      let isSoldLot = (lot.status === 'sold' || lot.status === 'soldAfterAuction')
      let displaySecondPrice = (lot.bidding === bidTypes.HEAD)
      let closedStr = `${makePrice(lot.closedPrice, { bidding: lot.bidding })}/${this.getBidding(lot.bidding)}`
      return (
        <div className={`lot-closed-state-container`}>
          <span className={`lot-closed-state ${lot.status}`}>
            <strong>{state}</strong> {closedStr}
            {isSoldLot && displaySecondPrice && ` ${priceKg}/kg`}
          </span>
          <span className={`lot-closed-state ${lot.status}`}>
            {isSoldLot && lot.winnerData && lot.winnerData.officeAddress && `To: ${lot.winnerData.officeAddress.town}, ${lot.winnerData.officeAddress.state}`}
          </span>
        </div>
      )
    }
  }

  getBidding (bidding) {
    switch (true) {
      case bidding === bidTypes.KG:
        return 'Kg'
      case bidding === bidTypes.HEAD:
      default:
        return 'Head'
    }
  }
  render () {
    return this.getLotClosedStatus(this.props.lot)
  }
}
