import React, { Component } from 'react'
import { makePrice } from '../../utils/Lots'
import { bidTypes } from '../../constants'

export default class LotDetailsClosedStatus extends Component {
  getBidding (bidding) {
    switch (true) {
      case bidding === bidTypes.KG:
        return 'Kg'
      case bidding === bidTypes.HEAD:
      default:
        return 'Head'
    }
  }

  getLotClosedStatus (lot) {
    let statuses = {
      noBid: 'No Bids',
      passed: 'Passed In',
      sold: 'SOLD',
      soldAfterAuction: 'SOLD AA'
    }
    let state = statuses[lot.status] || ''
    if (state) {
      let closedStr = `${makePrice(lot.closedPrice, { bidding: lot.bidding })}/${this.getBidding(lot.bidding)}`
      let priceKgStr = `${makePrice(lot.closedPrice / lot.publicDetails.weight.curfew, {
        bidding: 'kg',
        precision: 0
      })}/kg`
      return (
        <span className={`lot-closed-state ${lot.status}`}>
          <strong>{state}</strong> {closedStr} {(state === statuses['sold'] || state === statuses['soldAfterAuction']) && lot.bidding === bidTypes.HEAD && priceKgStr}
        </span>
      )
    }
  }

  getPurchaserLocation (lot) {
    // let lot = this.state.lot
    return (
      lot.winnerData && lot.winnerData.officeAddress &&
      <span className='lot-purchaser-location'>To: {lot.winnerData.officeAddress.town}, {lot.winnerData.officeAddress.state}</span>
    )
  }

  render () {
    const { lot } = this.props
    return (
      <p>
        <span className='lot-title'>{lot.publicDetails.title} {lot.publicDetails.summary.age} {lot.additionalTitle}</span>
        {this.getLotClosedStatus(lot)}
        {(lot.status === 'sold' || lot.status === 'soldAfterAuction') && this.getPurchaserLocation(lot)}
      </p>
    )
  }
}
