import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import  {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faCaretDown,
  faSortAlphaUp,
  faSortAlphaDown
} from '@fortawesome/fontawesome-free-solid'
import '../SortDropdown/SortDropdown.scss'

class SortDropdown extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.open = this.open.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)

    this.innerContainer = React.createRef()
  }
  componentDidMount () {
    // set first filterOption as default if localStorage one is not present in sortOptions
    if (this.props.sortOptions.indexOf(this.props.sortChoice) === -1) {
      this.props.changeSortChoice(this.props.sortOptions[0])
    }
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        document.addEventListener('click', this.handleDocumentClick)
      } else {
        document.removeEventListener('click', this.handleDocumentClick)
      }
    }
  }
  open () {
    if (!this.state.isOpen) this.setState({ isOpen: true })
  }
  handleDocumentClick (event) {
    if (!this.state.isOpen) {
      return
    }
    this.setState({ isOpen: false })
  }
  render () {
    const {
      sortOptions,
      sortChoice,
      sortDirection,
      changeSortChoice,
      changeSortDirection
    } = this.props
    const { isOpen } = this.state
    return (
      <div className={`sort-component${isOpen ? ' opened' : ''}`}>
        <div className='sort-dropdown'>
          <div
            className={`btn dropdown-btn${isOpen || sortChoice ? ' btn-opened dropdown-btn-active' : ''}`}
            onClick={this.open}>
            <span>Sort</span>
            <span className='icon-wrapper'><FontAwesomeIcon icon={faCaretDown} /></span>
          </div>
          <div ref={this.innerContainer} className='dropdown-choices'>
            {sortOptions.map((option, index) => (<span key={index} className={`dropdown-choice ${sortChoice === option ? 'selected' : ''}`} onClick={() => changeSortChoice(option)}>{option}</span>))}
          </div>
        </div>
        <div className='sort-direction-btn' onClick={changeSortDirection}>
          <span className='icon-wrapper'>
            <FontAwesomeIcon
              icon={sortDirection === 'up' ? faSortAlphaUp : faSortAlphaDown}
            />
          </span>
        </div>
      </div>
    )
  }
}

SortDropdown.propTypes = {
  sortOptions: PropTypes.array.isRequired,
  sortChoice: PropTypes.string.isRequired,
  sortDirection: PropTypes.oneOf(['up', 'down']),
  changeSortChoice: PropTypes.func.isRequired,
  changeSortDirection: PropTypes.func.isRequired
}

export default SortDropdown
