let checkNested = (mode, element, findFunc,
  options = {}) => {
  const { ignoreRFNumber = false, ignoreClosedRFBool = false } = options
  // mode = ['findOne', 'findAll'] enum
  switch (mode) {
    case 'findOne':
      if (!element.values) return
      switch (element.type) {
        case 'group':
          return findField(element.values, findFunc, options)
        case 'repeatForBool':
        case 'repeatForNumber':
          if (element.type === 'repeatForNumber' && ignoreRFNumber) return
          if (element.type === 'repeatForBool' &&
            ignoreClosedRFBool &&
            !element.value) {
            return
          }
          for (let fieldset of element.values) {
            let findElem = findField(fieldset, findFunc, options)
            if (findElem) return findElem
          }
          break
        default:
          throw new Error(`Unknown field type ${element.type}`)
      }
      break
    case 'findAll':
      if (!element.values) return []
      switch (element.type) {
        case 'group':
          return findAllFields(element.values, findFunc, options)
        case 'repeatForBool':
        case 'repeatForNumber':
          if (element.type === 'repeatForNumber' && ignoreRFNumber) return []
          if (element.type === 'repeatForBool' &&
            ignoreClosedRFBool &&
            !element.value) {
            return []
          }
          let resultArr = []
          for (let fieldset of element.values) {
            let res = findAllFields(fieldset, findFunc, options)
            if (res.length) resultArr = resultArr.concat(res)
          }
          return resultArr
        default:
          throw new Error(`Unknown field type ${element.type}`)
      }
    default:
      throw new Error('Unknown findField mode')
  }
}
/**
 * Find a particular element from lot.details
 * element - any form field object, or form details.values array
 *  e.g. lot.details, lot.details.values
 * findFunc - function that returns true if element fits the search
 * options
 *   ignoreRFNumber - set to true to skip fields nested in repeatForNumber
 *    (much faster - can potentially skip 20000+ fields)
 *   ignoreClosedRFBool - set to true to skip fields
 *    nested in repeatForBool with value === false (closed)
 * (recursive)
 */
export function findField (element, findFunc, options = {}) {
  if (!element) return
  if (!Array.isArray(element)) {
    let findElem = checkNested('findOne', element, findFunc, options)
    if (findElem) return findElem
  } else {
    for (let child of element) {
      if (findFunc(child)) {
        return child
      }
      let findElem = checkNested('findOne', child, findFunc, options)
      if (findElem) return findElem
    }
  }
}
// same as findField, but returns an array with all matches (recursive)
export function findAllFields (element, findFunc, options = {}) {
  if (!element) return []
  let resultArr = []
  if (!Array.isArray(element)) {
    let res = checkNested('findAll', element, findFunc, options)
    if (res.length) resultArr = resultArr.concat(res)
  } else {
    for (let child of element) {
      if (findFunc(child)) {
        resultArr = resultArr.concat(child)
      }
      let res = checkNested('findAll', child, findFunc, options)
      if (res.length) resultArr = resultArr.concat(res)
    }
  }
  return resultArr
}

export function findFieldByPublicId (details, publicId,
  { ignoreRFNumber = true, ignoreClosedRFBool = false } = {}) {
  return findField(details,
    el => el.publicId && el.publicId === publicId,
    { ignoreRFNumber, ignoreClosedRFBool })
}
export function findFieldByTitle (details, fieldTitle,
  { ignoreRFNumber = true, ignoreClosedRFBool = false } = {}) {
  // case-insensitive
  return findField(details,
    el => el.title.toLowerCase() === fieldTitle.toLowerCase(),
    { ignoreRFNumber, ignoreClosedRFBool })
}
export function findAllInvalidFields (elements,
  { ignoreRFNumber = true, ignoreClosedRFBool = true } = {}) {
  return findAllFields(elements, el => el.invalid,
    { ignoreRFNumber, ignoreClosedRFBool })
}

export function getPathByKeyAndValue (obj, key, value, path) {
  try {
    if (typeof obj !== 'object') {
      return
    }
    for (let subKey in obj) {
      if (obj.hasOwnProperty(subKey)) {
        let subPath = path
        let subValue = obj[subKey]
        let newPath
        if (!path) {
          newPath = subKey
        } else {
          newPath = path + '.' + subKey
        }
        if (subKey === key && subValue === value) {
          return newPath
        } else if (typeof subValue !== 'object') {
          newPath = subPath
        }
        let res = getPathByKeyAndValue(subValue, key, value, newPath)
        if (res) {
          return res
        }
      }
    }
  } catch (e) {}
}
