import React, { PureComponent } from 'react'
import { getLotNumber } from '../../utils/Auctions'

export default class LotItemWithdrawn extends PureComponent {
  render () {
    const { lot, auction, action } = this.props
    return (
      <div className='lot-item lot-item-withdrawn' key={lot._id}>
        <div className='lot-img-container lot-img-container-withdrawn' />
        <div className='lot-info'>
          <div className='lot-info-top'>
            <p className='lot-number'>{ getLotNumber(auction, lot) } - Withdrawn from Auction</p>
          </div>
          <div className='lot-info-middle'>
            <div className='left'>
              <p className='lot-title'>{lot.publicDetails.titleLive}</p>
            </div>
            {action && <button className='btn' onClick={() => action(lot)} variant='primary'>Copy Lot</button>}
          </div>
        </div>
      </div>
    )
  }
}
