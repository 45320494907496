import React from 'react'
import ReactDOM from 'react-dom'
import { persistStore } from 'redux-persist'

import store from './services/store'
import App from './AppContainer'

persistStore(store, null, () => {
  ReactDOM.render(
    (
      <App store={store} />
    ),
    document.getElementById('root')
  )
})
