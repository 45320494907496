import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import axios from 'axios'
import memoize from 'memoize-one'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEnvelope,
  faArrowDown,
  faPrint,
  faSearch,
  faCaretDown,
  faTimes,
  faFilter,
  faBalanceScale
} from '@fortawesome/fontawesome-free-solid'
import { faCompass, faCalendarAlt } from '@fortawesome/free-regular-svg-icons'
import update from 'immutability-helper'

import FilterMultiSelect from '../../FilterMultiSelect/FilterMultiSelect'
import FilterRange from '../../FilterRange/FilterRange'
import FilterRangeAge from '../../FilterRangeAge/FilterRangeAge'

import { getKindOptions } from '../../../utils/FindFieldKind'

import BackButton from '../../BackButton/BackButton'
import Api from '../../../services/api'
import { momentTz } from '../../../utils'
import { getSexesByKinds, getStockCategoriesByKinds } from '../../../utils/Lots'
import { sortLots, filterLots } from '../../../utils/Filter'
import EmailModalAuction from '../../Modal/EmailModalAuctionContainer'
import ImageCarousel from '../../ImageCarousel/ImageCarousel'
import LotItemWithdrawn from '../../LotItemWithdrawn/LotItemWithdrawn'
import { BASE_URL } from '../../../config'
import {withRouter} from "react-router-dom"
import InfiniteScroll from 'react-infinite-scroll-component'
import { WanderingCubes } from 'better-react-spinkit'
import SortDropdown from '../../SortDropdown/SortDropdown'
import LotsListClosedStatus from '../../LotsLists/LotsListClosedStatus'
import LotBottomInfo from '../../LotBottomInfo/LotBottomInfo'
import './LotsList.scss'

class LotsList extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      auction: null,
      lots: [],
      modalIsOpen: false,
      downloadModal: false,
      page: 1,
      limit: 10,
      hasMore: true,
      fetching: false,
      requestFinish: true,
      sortType: 'number',
      sortDirection: 1,
      filters: {
        screen: this.getScreen(),
        breed: this.getSavedFilterValue('breed', []),
        stockCategory: this.getSavedFilterValue('stockCategory', []),
        sex: this.getSavedFilterValue('sex', []),
        age: this.getSavedFilterValue('age', []),
        weight: this.getSavedFilterValue('weight', []),
        location: this.getSavedFilterValue('location', [])
      }
    }
    this.cancelSource = axios.CancelToken.source()
    this.downloadModal = true

    this.sort = memoize(this.sort)
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.renderLot = this.renderLot.bind(this)
    this.filterChangeHandler = this.filterChangeHandler.bind(this)
    this.clearFilter = this.clearFilter.bind(this)
    this.filterOptions = [
      'Lot Number',
      'Average Age',
      'Assessed Live Weight',
      'Sex',
      'Breed',
      'Stock Category'
    ]
    this.lotListRef = React.createRef()
  }
  componentDidMount () {
    this.getAuction()
    this.getLots()
    window.scrollTo(0, 0)
  }
  componentDidUpdate (prevProps, prevState, snapshot) {
    let scrollPosition = parseInt(window.localStorage.getItem('savedResultsScrollPosition')) || 0
    let countOfLots = parseInt(window.localStorage.getItem('savedResultCountOfLots')) || 0
   if (this.state.filters !== prevState.filters) {
      this.props.mergeStateProp('filters', [this.state.filters], 'user')
    }
    if (countOfLots !== 0 && scrollPosition !== 0) {
      window.scrollTo(0, scrollPosition)
      if (this.state.lots.length === countOfLots) {
        window.localStorage.removeItem('savedResultsScrollPosition')
        window.localStorage.removeItem('savedResultCountOfLots')
      }
    }
  }

  componentWillUnmount () {
    this.cancelSource.cancel()
  }
  checkProps () {
    if (this.state.auction) {
      let kindTitle = this.state.auction.kindData.title
      if (kindTitle.toLowerCase() !== this.props.match.params.kindTitle.toLowerCase()) {
        // if wrong kindTitle
        this.props.history.replace(`/main/results/${kindTitle}/${this.props.match.params.auctionNumber}`)
      }
    }
  }

  getLots = () => {
    const { requestFinish } = this.state
    if (requestFinish) {
      this.setState({
        fetching: true,
        hasMore: false,
        requestFinish: false
      })
      Api.getLotsByAuction(
        this.props.match.params.auctionNumber,
        { state: 'closed', cancelToken: this.cancelSource.token }
      ).then((data) => {
        console.log(data,"data")
        const { headers } = data
        let newLots = data.lots
        let lots = this.state.lots.length ? this.state.lots.concat(newLots) : newLots
        let currentPage = headers ? headers['x-current-page'] : 1
        let totalPages = headers ? headers['x-total-pages'] : 1
        this.setState({
          lots: lots&&lots.length?lots.filter(lot => lot.approved):[],
          page: this.state.page + 1,
          hasMore: parseInt(currentPage, 10) < totalPages,
          fetching: false,
          requestFinish: true
        }, () => { this.checkProps() })
      })
    }
  }
  getAuction () {
    Api.getAuction(this.props.match.params.auctionNumber, { cancelToken: this.cancelSource.token })
      .then((data) => {
        this.setState({
          auction: data.auction
        })
      }, () => {
        this.getLots()
      })
  }
  getSavedFilterValue (key, defaultValue = []) {
    let screen = this.getScreen()
    let thisFilter = this.props.filters.find(filterSet => filterSet.screen === screen)
    let value
    if (thisFilter) {
      value = thisFilter[key]
    }
    return value || defaultValue
  }
  getLotLocation (lot) {
    let addresses = []
    let possibleLocations = ['town', 'state']
    possibleLocations.forEach(loc => {
      let str = lot.publicDetails.location[loc]
      if (str) {
        addresses.push(str)
      }
    })
    return addresses.join(', ')
  }
  getScreen () {
    return `upcomingLotsList-${this.props.match.params.auctionNumber}`
  }
  sort (lots) {
    return sortLots(lots, 'Lot Number', 'down')
  }
  goToLink (lot) {
    return `/main/results/${this.props.match.params.kindTitle}/${this.props.match.params.auctionNumber}/${lot.searchNumericId}`
  }

  openModal (isDownload, printLink) {
    this.setState({
      printLink,
      downloadModal: isDownload,
      modalIsOpen: true
    })
  }
  closeModal () {
    this.setState({
      modalIsOpen: false
    })
  }
  getDisplayData (lots, filters, sortChoice, sortDirection) {
    let filteredLots = filterLots(lots, filters)
    return sortLots(filteredLots, sortChoice, sortDirection)
  }
  filterChangeHandler (filterName, value) {
    this.setState({
      filters: update(this.state.filters, {
        [filterName]: { $set: value }
      })
    })
  }
  clearFilter () {
    this.setState(update(this.state, {
      filters: {
        showMode: { $set: 'all' },
        breed: { $set: [] },
        stockCategory: { $set: [] },
        sex: { $set: [] },
        age: { $set: [] },
        weight: { $set: [] },
        location: { $set: [] }
      }
    }))
  }
  pdfUrl () {
    return `${BASE_URL}print/auctions/${this.state.auction.searchNumber}/auction-summary?type=pdf&mode=print`
  }
  selectOptions (kinds, auction, locationStates) {
    let kindObj = kinds.find(kind => kind._id === auction.kind)
    return {
      breed: getKindOptions(kindObj, 'majorBreed', { sort: true }),
      stockCategory: getStockCategoriesByKinds(kindObj.type, { sort: true }),
      sex: getSexesByKinds(kindObj.type, { sort: true }),
      location: locationStates
    }
  }
  rangeOptions (lots) {
    // find min/max values only in approved lots (draft lots can have crazy values)
    lots = lots.filter(lot => lot.approved)
    let ages = lots.map(lot => lot.publicDetails.age.max).filter(Boolean)
    let weights = lots.map(lot => lot.publicDetails.weight.average).filter(Boolean)
    let ageMax = Math.round(Math.max(...ages))
    let weightMax = Math.round(Math.max(...weights))
    return {
      age: {
        min: 0,
        max: isFinite(ageMax) ? Math.max(ageMax, 0) : 0
      },
      weight: {
        min: 0,
        max: isFinite(weightMax) ? Math.max(weightMax, 0) : 0
      }
    }
  }
  changeSortChoice = (sortChoice) => {
    this.props.changeStateProp('lotSort', { choice: sortChoice,
      direction: this.props.sortDirection }, 'main')
  }
  changeSortDirection = () => {
    this.props.changeStateProp('lotSort', { choice: this.props.sortChoice,
      direction: this.props.sortDirection === 'up' ? 'down' : 'up' }, 'main')
  }
  renderLot (lot) {
    const { auction } = this.state
    if (lot.withdrawn) {
      return (
        <LotItemWithdrawn lot={lot} key={lot._id} />
      )
    }
    return (
      <div className='lot-item' key={lot._id}>
        <div className='lot-img-container'>
          <ImageCarousel
            lot={lot}
            lotId={lot._id}
            media={lot.media}
            kindType={auction.kindData.type}
            preview />
        </div>
        <div className='lot-info'>
          <div className='lot-info-top'>
            <p className='lot-number'>Lot {lot.number || 'X'}</p>
            <p className='lot-location'>{this.getLotLocation(lot)}</p>
          </div>
          <div className='lot-info-middle'>
            <div className='left'>
              <p className='lot-title'>{`${lot.publicDetails.title} ${lot.publicDetails.summary.age} ${lot.additionalTitle}`}</p>
              <LotsListClosedStatus lot={lot} />
            </div>
            <div className='info-btns'>
              <Link className='btn' onClick={() => {
                window.localStorage.setItem('savedResultsScrollPosition', window.scrollY)
                window.localStorage.setItem('savedResultCountOfLots', this.state.lots.length)
              }} to={`/main/results/${auction.kindData.title}/${auction.searchNumber}/${lot.searchNumericId}`}>
                <span>View Details</span>
                <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faSearch} /></span>
              </Link>
            </div>
          </div>
          <div className='lot-info-bottom'>
            <div className='info-wrap'>
              <LotBottomInfo lot={lot} />
            </div>
            <div className='company-logo-wrapper'>
              {
                lot.creatorData.logo &&
                <img src={lot.creatorData.logo.url} alt='Company logo' />
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
  render () {
    const { kinds, locationStates, sortChoice, sortDirection } = this.props
    const { hasMore, lots, auction, filters } = this.state
    if (!auction) {
      return <div />
    }
    const { breed, stockCategory, sex, age, weight, location } = filters
    const displayLots = this.getDisplayData(lots, filters, sortChoice, sortDirection)
    const selectOptions = this.selectOptions(kinds, auction, locationStates)
    const ranges = this.rangeOptions(this.state.lots)
    return (
      <div className='lots-wrapper'>
        <EmailModalAuction
          openModal={this.openModal}
          closeModal={this.closeModal}
          afterOpenModal={this.afterOpenModal}
          isOpen={this.state.modalIsOpen}
          auction={auction}
          downloadModal={this.state.downloadModal}
          printLink={this.state.printLink}
          closed
        />
        <div className='top-header'>
          <div className='left'>
            <h1 className='auction-title'>{auction.title}</h1>
            <h2 className='auction-subtitle'>Auction No. {auction.number}</h2>
            <p className='auction-closed-date'>{momentTz(auction.liveAt).format('dddd D MMMM YYYY, h.mm A')}</p>
            {auction.description && (<p className='auction-desc'>{auction.description}</p>)}
          </div>
          <div className='right'>
            <div className='auction-logo'>
              {auction.logo && <img src={auction.logo.url} alt='Auction Logo' />}
            </div>
          </div>
        </div>
        <div className='top-btns results'>
          <BackButton onPress={() => {
            this.props.history.goBack()
          }} name='Back to All Results' />
          {auction.lotCount
            ? (
              <Fragment>
                <span className='btn' onClick={() => (this.openModal(false))}>
                  <span>Email Results</span>
                  <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faEnvelope} /></span>
                </span>
                <span className='btn' onClick={() => (this.openModal(true))}>
                  <span>Download Results</span>
                  <span className='icon-wrapper icon-warning'><FontAwesomeIcon icon={faArrowDown} /></span>
                </span>
                <span className='btn' onClick={() => (this.openModal(false, this.pdfUrl()))}>
                  <span>Print Results</span>
                  <span className='icon-wrapper icon-success'><FontAwesomeIcon icon={faPrint} /></span>
                </span>
              </Fragment>
            )
            : null}

        </div>
        <div className='lots-controls'>
          <h3 className='filters-heading'>Filters</h3>
          <div className='lots-filters-wrapper'>
            <FilterMultiSelect
              title='Breed'
              name='breed'
              options={selectOptions.breed}
              selectedOptions={breed}
              onChange={this.filterChangeHandler}
             icon={<FontAwesomeIcon icon={faFilter} />}
            />
            <FilterMultiSelect
              title='Sex'
              name='sex'
              options={selectOptions.sex}
              selectedOptions={sex}
              onChange={this.filterChangeHandler}
             icon={<FontAwesomeIcon icon={faCaretDown} size='lg' />}
            />
            <FilterMultiSelect
              title='Stock Category'
              name='stockCategory'
              options={selectOptions.stockCategory}
              selectedOptions={stockCategory}
              onChange={this.filterChangeHandler}
             icon={<FontAwesomeIcon icon={faCaretDown} size='lg' />}
            />
            <FilterRangeAge
              title='Age'
              name='age'
              unitY='Years'
              unitM='Months'
              description='Please type your minimum and maximum age in Months or Years or use slide below.'
              belowDescription='Please note: the filters are set from zero to the highest age available in the Catalogue.'
              min={ranges.age.min}
              max={ranges.age.max}
              value={age}
              onChange={this.filterChangeHandler}
             icon={<FontAwesomeIcon icon={faCalendarAlt} />}
            />
            <FilterRange
              title='Weight'
              name='weight'
              unit='Kg'
              description='Please type your minimum and maximum weight or use slide below.'
              belowDescription='Please note: the filters are set from zero to the highest weight available in the Catalogue.'
              min={ranges.weight.min}
              max={ranges.weight.max}
              value={weight}
              onChange={this.filterChangeHandler}
             icon={<FontAwesomeIcon icon={faBalanceScale} />}
            />
            <FilterMultiSelect
              title='Location'
              name='location'
              options={selectOptions.location}
              selectedOptions={location}
              onChange={this.filterChangeHandler}
             icon={<FontAwesomeIcon icon={faCompass} />}
            />
            <div
              className='btn filter-btn filter-btn-sm clear-all-btn'
              onClick={this.clearFilter}
            >
              <span>Clear All</span>
              <span className='icon-wrapper icon-danger'><FontAwesomeIcon icon={faTimes} /></span>
            </div>
          </div>
        </div>
        <SortDropdown
          sortOptions={this.filterOptions}
          sortChoice={this.props.sortChoice}
          changeSortChoice={this.changeSortChoice}
          sortDirection={this.props.sortDirection}
          changeSortDirection={this.changeSortDirection}
        />
        <React.Fragment>
          {auction.resultTable && auction.resultTable.categories.length > 0
            ? <div className='table-wrapper table-auction-results'>
              <div className='table-title'>{`Auction Results - Auction No. ${auction.number ? auction.number : ''}`}</div>
              <div className='table-header'>
                <div>Sex</div>
                <div>Head</div>
                <div>Lots</div>
                <div>Sold</div>
                <div>No Bids</div>
                <div>Passed In</div>
              </div>
              {auction.resultTable.categories.map((el, index) => {
                return (
                  <span className={`table-row ${auction.resultTable.categories.length === 1 && 'single-row'}`} key={index} >
                    <div>{el.sex}</div>
                    <div>{el.head}</div>
                    <div>{el.lots}</div>
                    <div>{el.sold}</div>
                    <div>{el.noBid}</div>
                    <div>{el.passed}</div>
                  </span>
                )
              })}
              {/* <div className='table-footer'>
              <div>Total</div>
              <div>{auction.resultTable.total.head}</div>
              <div>{auction.resultTable.total.lots}</div>
              <div>{auction.resultTable.total.sold}</div>
              <div>{auction.resultTable.total.noBid}</div>
              <div>{auction.resultTable.total.passed}</div>
            </div> */}
            </div>
            : null}
        </React.Fragment>
        <div className='lots-list results' ref={this.lotListRef}>
          <InfiniteScroll
            next={this.getLots}
            hasMore={hasMore}
            scrollThreshold={0.8}
            dataLength={lots.length}
            style={{ overflow: 'initial' }}>
            <React.Fragment>
              {displayLots.map(lot => this.renderLot(lot))}
              {this.state.fetching && <div className={`scroll-loader-wrap ${this.state.fetching ? 'active' : ''}`}>
                <WanderingCubes className='scroll-loader' color={'gray'} />
              </div>}
            </React.Fragment>
          </InfiniteScroll>

        </div>
        {/* {!this.state.lots.length && (
          <div className='no-data-placeholder'>
            <div className='message'>
              There are no available lots in this auction
            </div>
          </div>
        )} */}
      </div>
    )
  }
}

LotsList.propTypes = {
  userId: PropTypes.string.isRequired,
  changeStateProp: PropTypes.func.isRequired,
  showSpinner: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
}

LotsList.defaultProps = {
}

export default withRouter (LotsList)
