import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import Api from '../../services/api'
import { BASE_URL } from '../../config'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
//import * as FontAwesomeIcon from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faTimes,
  faArrowDown
} from '@fortawesome/fontawesome-free-solid'

class EmailModalAuction extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      recipientName: this.props.userData.firstName,
      recipientEmail: this.props.userData.email,
      docType: '' // enum ['summary', 'full']
    }

    this.handleInputChange = this.handleInputChange.bind(this)
    this.sendEmailRequest = this.sendEmailRequest.bind(this)
  }

  handleInputChange ({ target: { name, value } }) {
    this.setState({
      [name]: value
    })
  }

  async sendEmailRequest (docType, screen) {
    let data = {
      recipientName: this.state.recipientName,
      recipientEmail: this.state.recipientEmail,
      docType: docType,
      type: screen
    }

    this.props.showSpinner(true)
    let res = await Api.emailAuctionInfo({
      auctionId: this.props.auction._id,
      data
    })
    if (res && res.success) {
      this.props.showModal({
        title: 'FarmGate',
        message: res.success
      })
      this.props.closeModal()
    }
    this.props.showSpinner(false)
  }

  isResultsScreen () {
    return window.location.href.includes('results')
  }

  makeModalHeader () {
    const { downloadModal, closed, printLink } = this.props
    if (downloadModal) {
      return 'Download Results'
    } else if (closed) {
      if (printLink) {
        return 'Print Results'
      } else {
        return 'Email Results'
      }
    } else {
      return 'Email Catalogue'
    }
  }

  convertSummaryPdfAddressToFullDetails (link) {
    return link.replace('auction-summary', 'full-details')
  }

  renderDescForDownloadAndPrintModals () {
    const { downloadModal, printLink } = this.props
    if (downloadModal) {
      return 'Please choose if you would like to download a summary of the results or the results in full detail.'
    } else if (printLink) {
      return 'Please choose if you would like to print a summary of the results or the results in full detail.'
    }
  }

  render () {
    const { downloadModal, closed, printLink } = this.props
    return (
      <Modal
        isOpen={this.props.isOpen}
        onAfterOpen={this.props.afterOpenModal}
        onRequestClose={this.props.closeModal}
        ariaHideApp={false}
        // shouldCloseOnOverlayClick={false}
        overlayClassName='overlay'
        className='content'
        portalClassName='ReactModalPortal-Email'
      >
        <h2 className='modal-header'>{this.makeModalHeader()}</h2>
        <span className='icon-close icon-danger' onClick={this.props.closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className={(downloadModal || printLink) ? 'download modal-body' : 'modal-body'}>
          {
            (downloadModal || printLink)
              ? (
                <h3 className='choice-desc'>{this.renderDescForDownloadAndPrintModals()}</h3>
              )
              : (
                <React.Fragment>
                  <input
                    type='text'
                    name='recipientName'
                    placeholder='Name'
                    className='modal-input'
                    onChange={this.handleInputChange}
                    defaultValue={this.state.recipientName} />
                  <input
                    type='text'
                    name='recipientEmail'
                    placeholder='Email'
                    className='modal-input'
                    onChange={this.handleInputChange}
                    defaultValue={this.state.recipientEmail} />
                  <h3 className='choice-desc'>
                    {closed
                      ? 'Please choose if you would like to email a summary of the results or the results in full detail.'
                      : 'Please choose if you would like to email a summary of the catalogue or the catalogue in full detail.'
                    }
                  </h3>
                </React.Fragment>
              )
          }

          <div className='buttons'>
            {
              (downloadModal || printLink)
                ? (
                  <React.Fragment>
                    <a className='button'
                      target={printLink ? '_blank' : ''}
                      href={downloadModal
                        ? `${BASE_URL}print/auctions/${this.props.auction.searchNumber}/auction-summary?type=pdf`
                        : printLink
                      }>
                      <span className='button-text'>Summary Results</span>
                      <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowDown} /></span>
                    </a>
                    <a className='button' name='fullDetail'
                      target={printLink ? '_blank' : ''}
                      href={downloadModal
                        ? `${BASE_URL}print/auctions/${this.props.auction.searchNumber}/full-details`
                        : this.convertSummaryPdfAddressToFullDetails(printLink)
                      }>
                      <span className='button-text'>Lots in Full Detail</span>
                      <span className='icon-wrapper'><FontAwesomeIcon icon={faArrowDown} /></span>
                    </a>
                  </React.Fragment>
                )
                : (
                  <React.Fragment>
                    <div className='button' onClick={this.sendEmailRequest.bind(null, 'summary', this.isResultsScreen() ? 'result' : 'catalogue')}>
                      <span className='button-text'>{closed ? 'Summary Results' : 'Summary Catalogue'}</span>
                      <span className='icon-wrapper'><FontAwesomeIcon icon={faEnvelope} /></span>
                    </div>
                    <div className='button' name='fullDetail' onClick={this.sendEmailRequest.bind(null, 'full', this.isResultsScreen() ? 'result' : 'catalogue')}>
                      <span className='button-text'>Lots in Full Detail</span>
                      <span className='icon-wrapper'><FontAwesomeIcon icon={faEnvelope} /></span>
                    </div>
                  </React.Fragment>
                )
            }
          </div>
        </div>
      </Modal>
    )
  }
}

EmailModalAuction.propTypes = {
  openModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  afterOpenModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  auction: PropTypes.object
}

EmailModalAuction.defaultProps = {
  afterOpenModal: () => {}
}

export default EmailModalAuction
