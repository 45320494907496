import base64 from 'base-64'
import axios from 'axios'

import {
  BASE_URL
} from '../config'
import {
  apiHeaders
} from '../constants'
import * as ApiAlerts from '../utils/ApiAlerts'
import DataURItoBlob from '../utils/DataURItoBlob'

axios.defaults.baseURL = BASE_URL
axios.defaults.withCredentials = true

axios.interceptors.request.use(function (config) {
  config.headers.platform = 'web'
  return config
}, function (error) {
  throw error
})

axios.interceptors.response.use(function (response) {
  return response.data || response.statusText || true
}, function (error) {
  throw error
})

export default class API {
  static getServerTime () {
    return axios('server-time', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error, { silent: true })
      })
  }

  static getUser (data = null, { scene } = {}) {
    let headers = {}
    if (data) {
      headers['Authorization'] = 'Basic ' + base64.encode(data.email + ':' + data.password)
    }
    return axios('users/me', {
      method: 'get',
      headers: headers
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error, { scene })
      })
  }

  static getUserMagicLink (token, { scene } = {}) {
    let headers = {}
    if (token) {
      headers['Authorization'] = 'Bearer ' + token
    }
    return axios('users/magic/me', {
      method: 'get',
      headers: headers
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error, { scene })
      })
  }

  static userLogout () {
    return axios('logout', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static postUser (data) {
    return axios('users', {
      method: 'post',
      data: { user: data }
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error, { scene: 'sign up' })
      })
  }

  static checkUniqueUser (data) {
    return axios('check-unique-user', {
      method: 'post',
      data: { user: data }
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static putUser (data) {
    return axios('users/me', {
      method: 'put',
      data: { user: data }
    })
      .catch(function (err) {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static putUserWatchList (data) {
    return axios('users/me/watchlist', {
      method: 'put',
      data: { watchList: data }
    })
      .catch(function (err) {
        return ApiAlerts.ErrorExtractor(err, { silent: true })
      })
  }

  static postUserLogo ({ data, mimeType }) {
    let file = DataURItoBlob(data)
    return axios('users/me/logo', {
      method: 'post',
      headers: {
        'content-type': mimeType
      },
      data: file
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static getBids (auctionId = null, lotId = null) {
    return axios('users/me/bids', {
      method: 'get',
      params: {
        auction: auctionId,
        lot: lotId
      }
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static getAutobids (lotId = null) {
    return axios('users/me/autobids', {
      method: 'get',
      params: {
        lot: lotId
      }
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static getContacts () {
    return axios('contacts/main', {
      method: 'get'
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static getLotBids (auctionId = null, lotId = null) {
    return axios('lots/bids/' + lotId + '/' + auctionId, {
      method: 'get'
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static setAutobid (data) {
    return axios('users/me/autobids', {
      method: 'put',
      data: { autobid: data }
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { silent: true })
      })
  }

  static stopAutobid (data) {
    return axios('users/me/autobids/stop', {
      method: 'put',
      data: { autobid: data }
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { silent: true })
      })
  }

  static getKinds () {
    return axios('kinds/public', {
      method: 'get'
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static getKind (kindId, { silent = false } = {}) {
    return axios('kinds/' + kindId, {
      method: 'get'
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { silent })
      })
  }

  /* static getAuctions () {
    let q = window.location.href.split('?client=')[1]
    return q ? axios(`/auctions/result/public-info?client=${q}`, {
      method: 'get'
    }) : axios(`/auctions/result/public-info`, {
     method: 'get'
   })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { silent: true })
      })
  } */

  static getLotsForListing () {
    return axios('lots/listing', {
      method: 'get'
    }).catch(err => {
      return ApiAlerts.ErrorExtractor(err)
    })
  }

  static getAuctionsByKind (kindId) {
    return axios('kinds/' + kindId + '/auctions', {
      method: 'get'
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }


  static getAuction (auctionId, { cancelToken, silent = false } = {}) {
    if (auctionId && auctionId.includes('&fbclid')) {
      auctionId = auctionId.split('&fbclid')[0]
    }
    if (auctionId && auctionId.includes('%2F')) {
      auctionId = auctionId.split('%2F')[0]
    }
    console.log('auctionId2', auctionId)

    return axios('auctions/' + auctionId + '/public-info', {
      method: 'get',
      cancelToken
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { silent: true })
      })
  }

  static getResults (page = null, limit = null, sortType = null, sortDirection = null, { cancelToken } = {}) {
      let q = window.location.href.split('?client=')[1]
      return q ? axios(`results/public-info?client=${q}`, {
        method: 'get'
      }) : axios(`results/public-info`, {
       method: 'get',
       params: {
        page,
        limit,
        sortType,
        sortDirection
      },
      cancelToken
     })
        .catch(err => {
          return ApiAlerts.ErrorExtractor(err, { silent: true })
        })
  }

  static getMyResults () {
    return axios('lots/results', {
      method: 'get'
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static getMyReoffers () {
    return axios('lots/reoffers', {
      method: 'get'
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static updateReoffersLot (auctionId, lotId, data) {
    return axios(`auctions/${auctionId}/lots/${lotId}/reoffer`, {
      method: 'put',
      data: { lot: data }
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static editLateLot (lotId, data) {
    return axios(`lots/${lotId}/late-edit`, {
      method: 'put',
      data: { lot: data }
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static getResultsByKind (kindId, page = null, limit = null, sortType = null, sortDirection = null, { cancelToken } = {}) {
    return axios(`kinds/${kindId}/results`, {
      method: 'get',
      params: {
        page,
        limit,
        sortType,
        sortDirection
      },
      cancelToken
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static getLotsWatchlist ({ cancelToken } = {}) {
    return axios('lots/watchlist', {
      method: 'get',
      cancelToken
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static getLotsSubmissions (page = null, limit = null, sortType = null, sortDirection = null, { cancelToken } = {}) {
    return axios('lots/submissions', {
      method: 'get',
      params: {
        page,
        limit,
        sortType,
        sortDirection
      },
      cancelToken
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static getLotsDrafts (page = null, limit = null, sortType = null, sortDirection = null, { cancelToken } = {}) {
    return axios('lots/drafts', {
      method: 'get',
      params: {
        page,
        limit,
        sortType,
        sortDirection
      },
      cancelToken
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }


  static getLotsByAuction (auctionId, { state } = {}) {
    if (auctionId && auctionId.includes('&fbclid')) {
      auctionId = auctionId.split('&fbclid')[0]
    }
    if (auctionId && auctionId.includes('%2F')) {
      auctionId = auctionId.split('%2F')[0]
    }
    console.log('auctionId', auctionId)
    // if (auctionId && (auctionId.includes('LC') || auctionId.includes('LS'))) {
    //   auctionId = window.location.href.match(/AC\d\d|AS\d\d/g)[0]
    // }
    return axios('auctions/' + auctionId + '/lots/public-info', {
      method: 'get',
      params: {
        state
      }
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { silent: true })
      })
  }

  static getLot (lotId = null, { mode, trackView = false, silent = false } = {}) {
    const headers = {}
    if (trackView) {
      headers[apiHeaders.LOT_PAGE_VIEW] = true
    }
    if (lotId && lotId.includes('&fbclid')) {
      lotId = lotId.split('&fbclid')[0]
    }
    if (lotId && lotId.includes('%2F')) {
      lotId = lotId.split('%2F')[1]
    }
    console.log('lotId', lotId)
    if (!lotId.includes('AC') || !lotId.includes('AS')) {
      return axios(`lots/${lotId}/public-info`, {
        method: 'get',
        headers,
        params: {
          mode
        }
      })
        .catch(err => {
          return ApiAlerts.ErrorExtractor(err, { silent: true })
        })
    }
  }

  static postLot (auctionId, data, { silent = false } = {}) {
    return axios('auctions/' + auctionId + '/lots', {
      method: 'post',
      data: { lot: data }
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { silent })
      })
  }

  static putLot ({ auctionId, lotId, data, detailsChanges, silent = false, emailToVendor = false } = {}) {
    let params = {}
    if (emailToVendor) {
      params.emailToVendor = true
    }
    return axios(`auctions/${auctionId}/lots/${lotId}`, {
      params,
      method: 'put',
      data: { lot: data, detailsChanges }
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { silent })
      })
  }

  static postLicenseFile ({ data, mimeType, name = 'Document' }) {
    let file = DataURItoBlob(data)
    return axios('users/me/license-file', {
      method: 'post',
      headers: {
        'content-type': mimeType,
        'file-name': encodeURIComponent(name)
      },
      data: file
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static postPolicyFile ({ data, mimeType, name = 'Document' }) {
    let file = DataURItoBlob(data)
    return axios('users/me/policy-file', {
      method: 'post',
      headers: {
        'content-type': mimeType,
        'file-name': encodeURIComponent(name)
      },
      data: file
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static deleteLotMedia (lotId, mediaKey) {
    return axios('lots/' + lotId + '/media/' + mediaKey, {
      method: 'delete'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static deleteLotDocument (lotId, docId) {
    return axios('lots/' + lotId + '/documents/' + docId, {
      method: 'delete'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static deleteLot (lotId) {
    return axios('lots/' + lotId, {
      method: 'delete'
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static emailAuctionInfo ({ auctionId, data }) {
    return axios('auctions/' + auctionId + '/email-info', {
      method: 'post',
      data
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static emailLotInfo ({ lotId, data }) {
    return axios(`lots/${lotId}/email-info`, {
      method: 'post',
      data
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static emailLotOwnerInfo ({ lotId, data }) {
    return axios(`lots/${lotId}/owner-email-info`, {
      method: 'post',
      data
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static changePassword (data, { scene } = {}) {
    return axios('users/me/change-password', {
      method: 'put',
      data: { user: data }
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { scene })
      })
  }

  static requestForgotPassword (email) {
    return axios('users/forgot', {
      method: 'post',
      data: email
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { scene: 'forgotPassword' })
      })
  }

  static resetForgotPassword (token, newPassword, scene) {
    return axios('users/reset/' + token, {
      method: 'post',
      data: newPassword
    })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err, { scene })
      })
  }

  static postLotMedia (lotId, data) {
    // let rand = Math.random()
    // console.log(rand)
    // if (rand < 0.5) {
    //   console.log('simulated upload error!')
    //   return new Promise((resolve, reject) => {
    //     resolve(false)
    //   })
    // }
    let file = DataURItoBlob(data.url)
    return axios.post(`lots/${lotId}/media`,
      file,
      {
        headers: {
          'content-type': data.mimeType,
          'file-timestamp': data.timestamp,
          'file-order': data.order
        },
        params: {
          main: data.main ? 'true' : null
        }
      })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static postMediaThumbnail (mediaKey, lotId, data) {
    let file = DataURItoBlob(data)
    return axios.post(
      'lots/' + lotId + '/media/' + mediaKey + '/thumbnail',
      file,
      {
        headers: {
          'content-type': file.type
        }
      })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static putMediaMain (mediaKey, lotId) {
    return axios('lots/' + lotId + '/media/' + mediaKey + '/main',
      {
        method: 'put',
        data: {}
      })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static postLotDocuments (lotId, data) {
    // let rand = Math.random()
    // console.log(rand)
    // if (rand < 0.5) {
    //   console.log('simulated upload error!')
    //   return new Promise((resolve, reject) => {
    //     resolve(false)
    //   })
    // }
    let file = DataURItoBlob(data.uri)
    return axios.post(
      'lots/' + lotId + '/documents',
      file,
      {
        headers: {
          'content-type': data.mimeType,
          'file-name': encodeURIComponent(data.name),
          'file-description': encodeURIComponent(data.description),
          'file-order': data.order
        }
      })
      .catch(err => {
        return ApiAlerts.ErrorExtractor(err)
      })
  }

  static editLotDocument (lotId, documentKey, data) {
    return axios(`lots/${lotId}/documents/${documentKey}`, {
      method: 'put',
      data: { document: data }
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static getPics () {
    return axios('pics', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static postPic (pic) {
    return axios('pics', {
      method: 'post',
      data: {
        pic: pic
      }
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static putPic (picId, pic) {
    return axios(`pics/${picId}`, {
      method: 'put',
      data: {
        pic: pic
      }
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static deletePic (picId) {
    return axios(`/pics/${picId}`, {
      method: 'delete'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static getStates () {
    return axios('states', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static getStockCategories () {
    return axios('stock-categories', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static getAssessmentForms () {
    return axios('assessment-forms', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error, { silent: true })
      })
  }

  static getFaqs () {
    return axios('faqs', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static getTerms () {
    return axios('terms', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static getTools () {
    return axios('tools', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static getVideos () {
    return axios('how-to-videos', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static agentSearch (state, name, location) {
    return axios(`users/agent-search`, {
      method: 'get',
      params: {
        state: state,
        name: name,
        location: location
      }
    })
  }

  static getManuals () {
    return axios('manuals', {
      method: 'get'
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static emailManual ({ manualId, data }) {
    return axios(`manuals/${manualId}/send-email`, {
      method: 'post',
      data
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }

  static emailMyAuctionResult ({ data }) {
    return axios(`lots/my-results/email`, {
      method: 'post',
      data
    })
      .catch(function (error) {
        return ApiAlerts.ErrorExtractor(error)
      })
  }
}
