//localhost
// export const BASE_URL = 'http://localhost:3000/api/v1/'
// export const SOCKET_URL = 'http://localhost:3000/'
// export const WEB_APP = 'http://localhost:4000'

//test
// export const BASE_URL = 'https://test-api.farmgatetest.com.au/api/v1/'
// export const SOCKET_URL = 'https://test-api.farmgatetest.com.au/'
// export const WEB_APP = 'https://test-webapp.farmgatetest.com.au/'

//live
export const WEB_APP = 'https://live.farmgateauctions.com.au/'
export const BASE_URL = 'https://fga.farmgateauctions.com.au/api/v1/'
export const SOCKET_URL = 'https://fga.farmgateauctions.com.au/'


