import styled from 'styled-components'

export const LotBoxValue = styled.p`
  font-size: 16px;
`

export const LotBoxContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 40px;
    min-width: 60px;
    padding: 0 5px;
    border-radius: 4px;
    border: 1px solid #5d5d5d;
    margin-bottom: 4px;
    color: #5d5d5d;
    font-family: Lato;
    font-size: 11px;
    font-weight: 400;
    margin-right: 8px;
    background-color: ${({ type }) => {
    if (type === 'average' || type === 'curfew') {
      return '#5d5d5d;'
    } else {
      return 'inherit;'
    }
  }}
  color: ${({ type }) => {
    if (type === 'average') {
      return 'lightgray;'
    } else if (type === 'curfew') {
      return '#e6bd2f;'
    } else {
      return '#5d5d5d'
    }
  }}
  p {
    margin: 0;
  }
`

export const TooltipContainer = styled.div`
  font-size: 14px;
  font-family: Lato;
  text-align: center;
`
