import moment from 'moment'

import ServerTime from '../services/ServerTime'
import { momentTz } from './index'
import {
  AUCTION_ADD_LIMIT,
  AUCTION_REOFFER_LIMIT
} from '../constants'

const epochStart = moment(0)

export function getDateDays (dateString) {
  return momentTz(dateString).diff(epochStart, 'days')
}

export function formatAuctionDate (date) {
  return momentTz(date).format('dddd D MMMM YYYY')
}

export function getSectionHeaderTitle (auctionDate, today) {
  let todayDays
  todayDays = today ? getDateDays(today) : getDateDays(new Date())
  let days = getDateDays(auctionDate)
  if (days === todayDays) {
    return 'Today'
  } else if (days === todayDays + 1) {
    return 'Tomorrow'
  } else {
    return formatAuctionDate(auctionDate)
  }
}

export function isAvailableAuction (auction) {
  if (!auction) {
    return false
  }
  if (auction.cancelled) {
    return false
  }
  let leftDate = moment(ServerTime.getTime()).add(AUCTION_ADD_LIMIT, 'days')
  let rightDate = new Date(auction.editLotDisabledAt)
  let liveAt = new Date(auction.liveAt).getTime()
  return auction.state === 'future' && (liveAt < leftDate && ServerTime.getTime() < rightDate)
}

export function isAvailableReofferAuction (auction) {
  if (!auction) {
    return false
  }
  if (auction.cancelled) {
    return false
  }
  let leftDate = moment(ServerTime.getTime()).add(AUCTION_REOFFER_LIMIT, 'days')
  let rightDate = new Date(auction.editLotDisabledAt)
  let liveAt = new Date(auction.liveAt)
  return auction.state === 'future' && (liveAt < leftDate && ServerTime.getTime() < rightDate)
}
export const getLotNumber = (auction, lot) => {
  let lotNumber
  if (auction) {
    let isStateToShowNumber =
      auction.state === 'open' ||
      auction.state === 'live' ||
      auction.state === 'closed'

    if (((!isStateToShowNumber && auction.showLotNumbers) || isStateToShowNumber) && lot.number) {
      lotNumber = `Lot ${lot.number}`
    } else if (lot.searchNumericId) {
      lotNumber = `ID${lot.searchNumericId.substr(2, lot.searchNumericId.length)}`
    } else {
      lotNumber = 'Lot X'
    }
  } else {
    if (lot.searchNumericId) {
      lotNumber = `ID${lot.searchNumericId.substr(2, lot.searchNumericId.length)}`
    } else {
      lotNumber = 'Lot X'
    }
  }
  return lotNumber
}