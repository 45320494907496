import { createSelector } from 'reselect'
import { isAvailableAuction, isAvailableReofferAuction } from '../utils/Auctions'

const auctions = (state) => state.auctions
const auctionId = (state) => state.auctionId
const auctionNumber = (state) => state.auctionNumber
const kindId = (state) => state.kindId

export const auctionById = createSelector(
  auctionId, auctions,
  (auctionId, auctions) => {
    return auctions.find(a => a._id === auctionId)
  }
)

export const auctionByNumber = createSelector(
  auctionNumber, auctions,
  (auctionNumber, auctions) => {
    return auctions.find(a => a.searchNumber === auctionNumber)
  }
)

export const resultAuctions = createSelector(
  auctions,
  (auctions) => {
    return auctions.filter(a => a.state === 'closed')
  }
)

export const lotEditAuctions = createSelector(
  auctions, kindId,
  (auctions, kindId) => {
    return auctions.filter(a => a.kind === kindId && isAvailableAuction(a))
  }
)

export const lotReofferAuctions = createSelector(
  auctions, kindId,
  (auctions, kindId) => {
    return auctions.filter(a => a.kind === kindId && isAvailableReofferAuction(a))
  }
)
