import moment from 'moment'

export function filterLots (lots = [], filterObj = {}) {
  lots = lots.slice()
  Object.entries(filterObj).forEach(([key, value]) => {
    if (!value.length) return
    switch (key) {
      case 'breed':
        lots = lots.filter(lot => value.includes(lot.publicDetails.majorBreed))
        break
      case 'location':
        lots = lots.filter(lot => value.includes(lot.publicDetails.location.state))
        break
      case 'sex':
        lots = lots.filter(lot => value.includes(lot.publicDetails.sex))
        break
      case 'stockCategory':
        lots = lots.filter(lot => {
          return value.some(v => lot.publicDetails.stockCategory.includes(v))
        })
        break
      case 'age':
        lots = lots.filter(lot => {
          let [min, max] = value
          let ageMin = lot.publicDetails.age.min
          let ageMax = lot.publicDetails.age.max
          return (min <= ageMin && max >= ageMax) ||
            (min >= ageMin && min <= ageMax) ||
            (max <= ageMax && max >= ageMin)
        })
        break
      case 'weight':
        lots = lots.filter(lot => {
          let [min, max] = value
          let weightMin = Math.round(lot.publicDetails.weight.min)
          let weightMax = Math.round(lot.publicDetails.weight.max)
          return (min <= weightMin && max >= weightMax) ||
            (min >= weightMin && min <= weightMax) ||
            (max <= weightMax && max >= weightMin)
        })
        break
      default:
        break
    }
  })
  return lots
}
export function sortLots (lots, sortType = 'Lot Number', sortDirection = 'down') {
  lots = lots.slice()
  let sortFunc
  switch (sortType) {
    case 'Lot Number':
    case 'Lot Order':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.number
        let v2 = secondItem.number
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    case 'No of head':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.count
        let v2 = secondItem.count
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    case 'Sex':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.publicDetails.sex
        let v2 = secondItem.publicDetails.sex
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    case 'Breed':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.publicDetails.majorBreed
        let v2 = secondItem.publicDetails.majorBreed
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    case 'Category':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.publicDetails.stockCategory
        let v2 = secondItem.publicDetails.stockCategory
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    case 'State':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.publicDetails.location.state
        let v2 = secondItem.publicDetails.location.state
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    case 'Assessed Live Weight':
    case 'Live Weight':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.publicDetails.weight.average
        let v2 = secondItem.publicDetails.weight.average
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    case 'Date':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.createdAt
        let v2 = secondItem.createdAt
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    case 'Updated':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.updatedAt
        let v2 = secondItem.updatedAt
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    case 'Average Age':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.publicDetails.age.average
        let v2 = secondItem.publicDetails.age.average
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    default:
      return lots
  }
  if (sortFunc) {
    lots.sort(sortFunc)
  }
  if (sortDirection === 'up') {
    lots.reverse()
  }
  return lots
}

export function filterAuctions (auctions = [], filterObj = {}) {
  auctions = auctions.slice()
  Object.entries(filterObj).forEach(([key, value]) => {
    switch (key) {
      case 'date':
        if (!value.startDay || !value.endDay) {
          return
        }
        auctions = auctions.filter(auction => {
          return moment(auction.liveAt) >= moment(value.startDay) &&
            moment(auction.liveAt) <= moment(value.endDay)
        })
        break
      case 'state':
        if (!value.length) {
          return
        }
        auctions = auctions.filter(auction => value.some(state => auction.location.state.includes(state)))
        break
      default:
        break
    }
  })
  return auctions
}
export function sortAuctions (auctions, sortType = 'Date', sortDirection = 'down') {
  auctions = auctions.slice()
  let sortFunc
  switch (sortType) {
    case 'Date':
      sortFunc = (firstItem, secondItem) => {
        let v1 = firstItem.liveAt
        let v2 = secondItem.liveAt
        return v1 < v2 ? -1 : v1 > v2 ? 1 : 0
      }
      break
    default:
      return auctions
  }
  if (sortFunc) {
    auctions.sort(sortFunc)
  }
  if (sortDirection === 'up') {
    auctions.reverse()
  }
  return auctions
}
export function setRangeValue (rangeValue, max, selectedRangeItem, innerWidth) {
  let [ currentMin, currentMax ] = rangeValue
  let diffrence
  if (max > 10) {
    if (innerWidth > 1650) {
      if (max > 100) {
        diffrence = 8 * Math.ceil(max / 100) + 1
      } else {
        diffrence = Math.ceil(max / 20) + 1
      }
    } else if (innerWidth > 550) {
      diffrence = Math.ceil(max / 5)
    } else if (innerWidth <= 550) {
      if (max > 100) {
        diffrence = Math.ceil(max / 6) + 1
      } else {
        diffrence = Math.ceil(max / 10) + 1
      }
    }
    if (currentMax - currentMin < diffrence) {
      if (selectedRangeItem === 'min') {
        if (currentMax - diffrence < 0) {
          return [0, diffrence]
        } else {
          return [currentMax - diffrence, currentMax]
        }
      } else if (selectedRangeItem === 'max') {
        if (currentMin + diffrence < max) {
          return [currentMin, currentMin + diffrence]
        } else {
          return [max - diffrence, max]
        }
      }
    }
  }
  return rangeValue
}