import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCheck
} from '@fortawesome/fontawesome-free-solid'
import FilterMultiItem from './FilterMultiItem/FilterMultiItem'
import { isElementInsideOf, checkFilterIntersection } from '../../utils'
import '../FilterMultiSelect/FilterMultiSelect.scss'

export default class FilterMultiSelect extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false
    }
    this.checkHandler = this.checkHandler.bind(this)
    this.uncheckHandler = this.uncheckHandler.bind(this)
    this.open = this.open.bind(this)
    this.close = this.close.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.onResize = this.onResize.bind(this)

    this.innerContainer = React.createRef()
  }
  componentDidUpdate (prevProps, prevState) {
    this.onResize()
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        document.addEventListener('click', this.handleDocumentClick)
        window.addEventListener('resize', this.onResize)
      } else {
        document.removeEventListener('click', this.handleDocumentClick)
        window.removeEventListener('resize', this.onResize)
      }
    }
  }
  componentWillUnmount () {
    document.removeEventListener('click', this.handleDocumentClick)
    window.removeEventListener('resize', this.onResize)
  }
  onResize () {
    let innerContainer = this.innerContainer.current
    checkFilterIntersection(innerContainer, 'filter-multi-inner-right')
  }
  checkHandler (option) {
    let newSelected = [...this.props.selectedOptions, option]
    this.props.onChange(this.props.name, newSelected)
  }
  uncheckHandler (option) {
    let newSelected = this.props.selectedOptions.filter(item => item !== option)
    this.props.onChange(this.props.name, newSelected)
  }
  renderOptions (optionsArray) {
    const { options, selectedOptions } = this.props
    const optionsPerColumn = 14
    const optionsLength = optionsArray.length
    if (optionsLength > optionsPerColumn) {
      const optionsNumOfColumns = Math.ceil(optionsLength / optionsPerColumn)
      const optionsColumns = []
      for (let i = 0; i < optionsNumOfColumns; i++) {
        optionsColumns.push(
          <ul key={`column-${i}`}>
            {
              options
                .slice(i * optionsPerColumn, (i + 1) * optionsPerColumn)
                .map(option => {
                  const isSelected = selectedOptions.includes(option)
                  return <FilterMultiItem
                    key={option}
                    option={option}
                    isSelected={isSelected}
                    checkHandler={this.checkHandler}
                    uncheckHandler={this.uncheckHandler}
                  />
                })
            }
          </ul>
        )
      }
      return optionsColumns
    }
    return (
      <ul>
        {
          options.map(option => {
            const isSelected = selectedOptions.includes(option)
            return <FilterMultiItem
              key={option}
              option={option}
              isSelected={isSelected}
              checkHandler={this.checkHandler}
              uncheckHandler={this.uncheckHandler}
            />
          })
        }
      </ul>
    )
  }
  open () {
    if (!this.state.isOpen) {
      this.setState({ isOpen: true })
    }
  }
  close () {
    if (this.state.isOpen) {
      this.setState({ isOpen: false })
    }
  }
  handleDocumentClick (event) {
    if (!this.state.isOpen ||
      isElementInsideOf(event.target, this.innerContainer.current)) {
      return
    }
    this.close()
  }
  render () {
    const {
      title,
      icon,
      options,
      selectedOptions,
      buttonLg
    } = this.props
    const {
      isOpen
    } = this.state
    const isActive = Boolean(isOpen || selectedOptions.length)
    return (
      <div
        className={`filter-multi-wrapper filter-multi-wrapper-md ${buttonLg ? 'filter-multi-wrapper-lg' : ''}`}>
        <div className={`btn filter-btn filter-btn-md ${buttonLg ? 'filter-btn-lg' : ''} ${isOpen ? 'btn-opened' : ''} ${isActive ? 'filter-btn-active' : ''}`}
          onClick={this.open}
        >
          <span>{title}</span>
          <span className='icon-wrapper'>{icon}</span>
        </div>
        { isOpen &&
          <div ref={this.innerContainer} className='filter-multi-inner'>
            <div className='filter-multi-options'>
              {this.renderOptions(options)}
            </div>
            <div className='filter-multi-buttons'>
              <div
                className='btn filter-btn filter-btn-sm'
                onClick={this.close}
              >
                <span>OK</span>
                <span className='icon-wrapper icon-green'><FontAwesomeIcon icon={faCheck} /></span>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

FilterMultiSelect.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  selectedOptions: PropTypes.array.isRequired,
  buttonLg: PropTypes.bool
}
