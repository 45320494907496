import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import update from 'immutability-helper'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faTimes, faCheck } from '@fortawesome/fontawesome-free-solid'
import Range from 'rc-slider/lib/Range'
import { setRangeValue } from '../../utils/Filter'
import { isElementInsideOf, checkFilterIntersection } from '../../utils'
import '../FilterRange/FilterRange.scss'

export default class FilterRange extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      valueMinWeight: (this.props.value && this.props.value[0]) || this.props.min,
      valueMaxWeight: (this.props.value && this.props.value[1]) || this.props.max,
      isOpen: false,
      inColumn: false,
      value: this.props.value || [],
      selectedRangeItem: ''
    }
    this.handleChange = this.handleChange.bind(this)
    this.apply = this.apply.bind(this)
    this.clear = this.clear.bind(this)
    this.open = this.open.bind(this)
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.onResize = this.onResize.bind(this)
    this.onFocusInput = this.onFocusInput.bind(this)
    this.changeValueMinWeight = this.changeValueMinWeight.bind(this)
    this.changeValueMaxWeight = this.changeValueMaxWeight.bind(this)

    this.innerContainer = React.createRef()
    this.inputRef = React.createRef()
    this.inputRef2 = React.createRef()
  }
  componentDidUpdate (prevProps, prevState) {
    if (prevProps.max !== this.props.max) {
      this.setState({
        valueMaxWeight: prevState.value.length ? prevState.value[1] : this.props.max
      })
    }
    this.onResize()
    if (prevProps.value !== this.props.value) {
      this.setState({
        value: this.props.value
      })
    }
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        this.onResize()
        document.addEventListener('click', this.handleDocumentClick)
        window.addEventListener('resize', this.onResize)
      } else {
        document.removeEventListener('click', this.handleDocumentClick)
        window.removeEventListener('resize', this.onResize)
      }
    }
  }
  componentWillUnmount () {
    document.removeEventListener('click', this.handleDocumentClick)
    window.removeEventListener('resize', this.onResize)
  }
  onResize () {
    window.innerWidth <= 1650 ? this.displayInColumn(true) : this.displayInColumn(false)
    let innerContainer = this.innerContainer.current
    checkFilterIntersection(innerContainer, 'filter-range-inner-right')
  }
  displayInColumn (inColumn) {
    this.setState({ inColumn })
  }
  handleChange (value) {
    let { selectedRangeItem } = this.state
    if (value[0] !== this.state.valueMinWeight) {
      selectedRangeItem = 'min'
    } else if (value[1] !== this.state.valueMaxWeight) {
      selectedRangeItem = 'max'
    }
    if (this.state.inColumn !== value) {
      const [valueMinWeight, valueMaxWeight] = setRangeValue(value, this.props.max, selectedRangeItem, window.innerWidth)
      this.setState({
        valueMinWeight,
        valueMaxWeight,
        value: [valueMinWeight, valueMaxWeight],
        selectedRangeItem
      })
    }
  }
  apply () {
    this.props.onChange(this.props.name, this.state.value)
    this.setState({ isOpen: false })
  }
  clear () {
    this.setState({
      valueMinWeight: this.props.min,
      valueMaxWeight: this.props.max
    })
    this.props.onChange(this.props.name, [])
  }
  open () {
    if (!this.state.isOpen) this.setState({ isOpen: true })
  }
  handleDocumentClick (event) {
    if (!this.state.isOpen ||
      isElementInsideOf(event.target, this.innerContainer.current)) {
      return
    }
    this.setState({ isOpen: false })
  }
  onFocusInput (ref) {
    ref.current.focus()
    ref.current.value = ''
  }
  onBlurMaxInput = (selector) => {
    if (this.state.valueMaxWeight < this.state.valueMinWeight) {
      if (selector === 'max') {
        this.setState({
          valueMaxWeight: this.state.valueMinWeight,
          value: update(this.state.value, {
            0: { $set: this.state.valueMinWeight },
            1: { $set: this.state.valueMinWeight }
          })
        })
      } else if (selector === 'min') {
        this.setState({
          valueMinWeight: this.props.min,
          value: update(this.state.value, {
            0: { $set: this.props.min },
            1: { $set: this.state.valueMaxWeight }
          })
        })
      }
    }
  }
  changeValueMinWeight (event) {
    this.setState({
      selectedRangeItem: 'min',
      valueMinWeight: !isNaN(event.target.value) ? +event.target.value >= this.state.valueMaxWeight ? this.state.valueMaxWeight : +event.target.value : 0,
      value: update(this.state.value, {
        0: { $set: +event.target.value >= this.state.valueMaxWeight ? this.state.valueMaxWeight : +event.target.value },
        1: { $set: this.state.valueMaxWeight }
      })
    })
  }
  changeValueMaxWeight (event) {
    this.setState({
      selectedRangeItem: 'max',
      valueMaxWeight: !isNaN(event.target.value) ? +event.target.value >= this.props.max ? this.props.max : +event.target.value : this.props.max,
      value: update(this.state.value, {
        0: { $set: this.state.valueMinWeight },
        1: { $set: !isNaN(event.target.value) ? +event.target.value <= this.state.valueMinWeight
          ? this.state.valueMinWeight
          : +event.target.value >= this.props.max
            ? this.props.max
            : +event.target.value : this.props.max }
      })
    })
  }
  render () {
    const {
      description,
      belowDescription,
      title,
      icon,
      min,
      max,
      unit,
      buttonLg
    } = this.props
    const { isOpen, inColumn, value, selectedRangeItem } = this.state
    // only display as active if has value, and its not the default one (min and max)
    const isActive = Boolean(isOpen ||
      (this.props.value.length && (min !== this.props.value[0] || max !== this.props.value[1])))
    return (
      <div
        className={`filter-range-wrapper filter-range-wrapper-md ${buttonLg ? 'filter-range-wrapper-lg' : ''}`}>
        <div className={`btn filter-btn filter-btn-md ${buttonLg ? 'filter-btn-lg' : ''} ${isOpen ? 'btn-opened' : ''} ${isActive ? 'filter-btn-active' : ''}`}
          onClick={this.open}
        >
          <span>{title}</span>
          <span className='icon-wrapper'>{icon}</span>
        </div>
        {
          isOpen &&
          <div ref={this.innerContainer}
            className={`filter-range-inner ${inColumn ? 'in-column' : ''}`}>
            <p>{description}</p>
            <div className={`slider-wrapper ${inColumn ? 'in-column' : ''}`}>
              <div>
                <span onClick={() => this.onFocusInput(this.inputRef)} className={`slider-counter slider-counter-left ${inColumn ? 'in-column' : ''}`}>
                  <input
                    ref={this.inputRef}
                    className='weightFilter'
                    type='text'
                    name='valueMinWeight'
                    value={this.state.valueMinWeight.toString()}
                    placeholder={this.state.valueMinWeight}
                    onChange={this.changeValueMinWeight}
                    onBlur={() => {
                      this.onBlurMaxInput('min')
                    }}
                  /> <span className='text-kg'>{unit}</span>
                </span>
              </div>
              <Range
                min={min}
                max={max}
                value={value.length ? setRangeValue(value, max, selectedRangeItem, window.innerWidth) : [min, max]}
                allowCross={false}
                onChange={this.handleChange}
              />
              <div>
                <span onClick={() => this.onFocusInput(this.inputRef2)} className={`slider-counter slider-counter-right ${inColumn ? 'in-column' : ''}`}>
                  <input
                    ref={this.inputRef2}
                    className='weightSecondField'
                    type='text'
                    name='valueMaxWeight'
                    value={this.state.valueMaxWeight.toString()}
                    placeholder={this.state.valueMaxWeight}
                    onChange={this.changeValueMaxWeight}
                    onBlur={() => {
                      this.onBlurMaxInput('max')
                    }}
                  /> <span className='text-kg'>{unit}</span>
                </span>
              </div>
            </div>
            <div className='below-description'>
              <p>{belowDescription}</p>
            </div>
            <div className='range-buttons'>
              <div
                className='btn filter-btn filter-btn-sm'
                onClick={this.apply}
              >
                <span>Apply</span>
                <span className='icon-wrapper icon-green'><FontAwesomeIcon icon={faCheck} /></span>
              </div>
              <div
                className='btn filter-btn filter-btn-sm'
                onClick={this.clear}
              >
                <span>Clear</span>
                <span className='icon-wrapper icon-danger'><FontAwesomeIcon icon={faTimes} /></span>
              </div>
            </div>
          </div>
        }
      </div>
    )
  }
}

FilterRange.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  unit: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  icon: PropTypes.element.isRequired,
  buttonLg: PropTypes.bool
}
