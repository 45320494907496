import React, { Component } from 'react'
import { Provider } from 'react-redux'
//import { HashRouter } from 'react-router-dom'
import { BrowserRouter as Router,Switch,Route } from 'react-router-dom'
import AuctionListContainer from './components/Results/AuctionList/AuctionListContainer'
import LotsListContainer from './components/Results/LotsList/LotsListContainer'
import LotDetailsContainer from './components/Results/LotDetails/LotDetailsContainer'
import './App.scss'
import './App.css'
//import Results from './components/Results/Results'
//import { onEnter } from './utils/UpdateData'

class App extends Component {
  render () {
    return (
      // <Provider store={this.props.store}>
      //   <HashRouter>
      //     <div className='App'>
      //       <div className='app-route'>
      //         <div className='inner-container'>
      //           <AuctionListContainer />
      //         </div>
      //       </div>
      //     </div>
      //   </HashRouter>
      // </Provider>
 <Provider store={this.props.store}>
<Router>
<div className='App'>
<div className='app-route'>
 <div className='inner-container'>
  <Switch>
    <Route path = '/' exact component= {AuctionListContainer}/>
    <Route path = '/main/results/:kindTitle/:auctionNumber' exact component= {LotsListContainer}/>
    <Route path = '/main/results/:kindTitle/:auctionNumber/:lotNumericId' exact component= {LotDetailsContainer}/>
  </Switch>
  </div>
  </div>
</div>
</Router>
</Provider>
    )
  }
}

export default App
