import axios from 'axios'
import { bindActionCreators } from 'redux'
import { showModal, showSpinner } from '../actions/index'
import logOut from './logOut'
import store from '../services/store'
import ReactGA from 'react-ga'

let bound = bindActionCreators({ showModal, showSpinner }, store.dispatch)

export function ErrorExtractor (error, { scene, silent = false } = {}) {
  // do not display any error if silent
  if (silent) {
    return false
  }

  if (axios.isCancel(error)) {
    // console.log('[API Request] Cancelled', error.message || '')
    return { cancel: true }
  }

  if (!error.response) {
    bound.showModal({
      title: 'Error',
      message: 'There was a problem with your network. Please try again later'
    })
    if (scene === 'sign in') {
      ReactGA.event({
        category: 'Failed Login',
        action: 'Network Error'
      })
    }
    return false
  }

  switch (error.response.status) {
    case 400:
      if (scene === 'sign up') {
        let fError = error.response.data.error.errors[0]
        if (fError && fError.code === 'duplicateFieldError') {
          bound.showModal({
            title: 'FarmGate',
            message: fError.message
          })
        } else {
          bound.showModal({
            title: 'FarmGate',
            message: 'Error occurred while trying to submit your data. Please check your internet connection and try again'
          })
        }
      }
      if (error.response.data.error.errors.length) {
        bound.showModal({
          title: 'Error',
          message: error.response.data.error.errors[0].message
        })
      } else {
        bound.showModal({
          title: 'Error',
          message: 'Something is wrong ' + error.message
        })
      }
      break
    case 401:
      if (scene === 'sign in') {
        ReactGA.event({
          category: 'Failed Login',
          action: 'Incorrect credentials'
        })
        return 401
      } else {
        logOut.init({ force: true })
      }
      break
    case 403:
      if (scene === 'sign in') {
        return 403
      }
      break
    case 404:
      if (scene === 'changeCurrentPassword') {
        bound.showModal({
          title: 'Error',
          message: 'Wrong Password. Password you entered is not valid'
        })
      } else if (scene === 'change-password') {
        bound.showModal({
          title: 'Wrong Security Code',
          message: 'Security Code you entered is not valid, please check it and try again'
        })
      } else if (scene === 'forgotPassword') {
        bound.showModal({
          title: 'Wrong Email',
          message: 'Email you entered does not belong to any account, please check it and try again'
        })
      } else {
        bound.showModal({
          title: 'Error',
          message: 'Not Found. Server error. Please try again later'
        })
      }
      break
    case 500:
      bound.showModal({
        title: 'Error',
        message: 'Network Error. Bad request. Please try again later'
      })
      break
    default:
      return false
  }
  return false
}
