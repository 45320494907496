import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import LotDocuments from '../LotDocuments/LotDocuments'
import { findFieldByPublicId } from '../../utils/FindFieldLot'
import IndividualAssessment from '../OptiWeigh/IndividualAssessment'
import OptiWeighAverageWeight from '../OptiWeigh/OptiWeighAverageWeight'
import OptiWeighDistribution from '../OptiWeigh/OptiWeighDistribution'
//import '../DetailedInfo/DetailedInfo.scss'
class DetailedInfo extends Component {
  renderRow(rowData, index) {
    return (
      <ListItem
        item={rowData}
        lot={this.props.lot}
        key={index}
        allFields={this.props.allFields}
      />
    )
  }

  render() {
    const { lot, showDocuments } = this.props
    if (!lot || !lot.details) {
      return <div />
    }
    return (
      <div className="details">
        {showDocuments ? <LotDocuments documents={lot.documents} /> : null}
        {lot.details.values.map((row, i) => {
          if (row.publicId === 'assessmentGroup') {
            if (
              lot.kindData.type === 'goat' &&
              (!lot.countWeighed || lot.countWeighed === 0)
            ) {
              return null
            } else {
              if (lot.optiweighAllowed) {
                return [
                  this.renderRow(
                    {
                      publicId: 'individualAssessmentOptiweigh',
                      type: 'group',
                      title: 'Individual Assessment',
                      data: this.props.lot.optiweigh.assessmentData,
                      values: [0],
                    },
                    Math.random(),
                  ),
                  this.renderRow(
                    {
                      publicId: 'mobAverage',
                      type: 'group',
                      title: 'Optiweigh - Mob Average Weights and ADG',
                      data: this.props.lot.optiweigh.averageData,
                      values: [0],
                    },
                    Math.random(),
                  ),
                ]
              } else {
                return this.renderRow(
                  {
                    publicId: 'individualAssessment',
                    type: 'group',
                    title: 'Individual Assessment',
                    data: this.props.lot.assessment,
                    values: [0],
                  },
                  i,
                )
              }
            }
          }
          return this.renderRow(row, i)
        })}
        <div className="row">
          <div className="row-title-wrap">
            <p className="row-title">Additional Information</p>
          </div>
          <div className="fields-container">
            <div className="one-column">
              <div className="option">
                <div className="option-line">
                  <p className="option-title">Sale Conditions</p>
                  <p className="option-value">{lot.saleConditions}</p>
                </div>
                <div className="option-line">
                  <p className="option-title">Option for Vendor’s Bid</p>
                  <p className="option-value">{lot.maxVendorBids || '-'}</p>
                </div>
                {this.props.allFields && (
                  <React.Fragment>
                    {lot.agentSignature && lot.agentSignature.url && (
                      <div className="option-line">
                        <p className="option-title">Agent Signature</p>
                        <p className="option-value">
                          <img
                            className="signature-img"
                            src={lot.agentSignature.url}
                            alt="Agent Signature"
                          />
                        </p>
                      </div>
                    )}
                    {lot.vendorSignature && lot.vendorSignature.url && (
                      <div className="option-line">
                        <p className="option-title">Vendor Signature</p>
                        <p className="option-value">
                          <img
                            className="signature-img"
                            src={lot.vendorSignature.url}
                            alt="Vendor Signature"
                          />
                        </p>
                      </div>
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
        {lot.state === 'closed' ? (
          <div className="row">
            <div className="row-title-wrap">
              <p className="row-title">NLIS Note</p>
            </div>
            <div className="fields-container">
              <div className="one-column">
                <div className="option">
                  <div className="option-line">
                    <p className="option-value">
                      <span className="paragraph">
                        If you are purchasing livestock via the FarmGate
                        Auctions platform, please remember its the buyers
                        responsibility to transfer your purchased animals onto
                        your PIC in the NLIS database.
                      </span>
                      <span className="paragraph">
                        To do this you can access your account at
                        <a
                          href="http://www.nlis.com.au"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          www.nlis.com.au
                        </a>
                        or if you require assistance, please contact the NLIS
                        Helpdesk on 1800 654 743
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

DetailedInfo.propTypes = {
  lot: PropTypes.object.isRequired,
  allFields: PropTypes.bool,
}

class ListItem extends React.PureComponent {
  renderDetails(details, repeat = false, reIndex = false) {
    let nodes = []
    if (details) {
      details.forEach((value, index) => {
        if (value.publicId === 'assessmentDate' && !this.canDisplay(value)) {
          nodes.push(this.renderAdditionalData(value, details))
        }
        if (
          value.type === 'repeatForBool' ||
          value.type === 'repeatForNumber'
        ) {
          if (!value.value && value.type === 'repeatForBool') {
            nodes.push(this.renderField(value, index + reIndex))
          } else {
            if (value.type === 'repeatForBool') {
              nodes.push(this.renderField(value, index + reIndex))
            }
            value.values.forEach((item) => {
              let nestedDetails = this.renderDetails(item, true, 're' + index)
              if (!nestedDetails.length) return
              nodes = nodes.concat(nestedDetails)
            })
          }
        } else {
          if (!this.canDisplay(value)) return
          nodes.push(this.renderField(value, index + reIndex, details))
        }
      })
    }
    return repeat ? nodes : this.prepareColumns(nodes)
  }

  prepareColumns(nodes) {
    let res = []
    if (nodes.length && nodes.length / 2 >= 1) {
      let columns = this.splitForTwoColumns(nodes)
      res.push(
        <div className="left-column" key={'left-column'}>
          {columns.first}
        </div>,
      )
      res.push(
        <div className="right-column" key={'right-column'}>
          {columns.second}
        </div>,
      )
    } else if (nodes.length) {
      res.push(
        <div className="one-column" key={'one-column'}>
          {nodes[0]}
        </div>,
      )
    }
    return res
  }

  renderAdditionalData(value, details, isOptiweigh = false) {
    let nodes = []
    switch (true) {
      case value.publicId === 'assessmentDate': {
        let multipler = this.props.lot.kindData.type === 'cattle' ? 1 : 1000
        nodes.push(
          <React.Fragment key="assessment-weight-data">
            {!isOptiweigh ? (
              <div className="option">
                <div className="option-line">
                  <p className={`option-title`}>Assessed Weight</p>
                  <p className={`option-value`}>
                    {this.props.lot.publicDetails.weight.average > 0
                      ? `${this.props.lot.publicDetails.weight.average} Kg`
                      : ' - '}
                  </p>
                </div>
              </div>
            ) : null}
            {!isOptiweigh ? (
              <div className="option">
                <div className="option-line">
                  <p className={`option-title`}>Weight Gain Adjustment</p>
                  <p className={`option-value`}>
                    {this.props.lot.weightGain > 0
                      ? this.props.lot.weightGain * multipler
                      : ' - '}
                    {this.props.lot.weightGain > 0
                      ? this.props.lot.kindData.type === 'cattle'
                        ? 'Kg'
                        : 'g'
                      : ''}
                  </p>
                </div>
              </div>
            ) : null}
            {!isOptiweigh ? (
              <div className="option">
                <div className="option-line">
                  <p className={`option-title`}>New Assessed Weight</p>
                  <p className={`option-value`}>
                    {this.props.lot.weightGain > 0
                      ? parseFloat(
                          (
                            this.props.lot.publicDetails.weight.average +
                            this.props.lot.weightGainTotal
                          ).toFixed(2),
                        )
                      : ' - '}
                    {this.props.lot.weightGain > 0 ? 'Kg' : ''}
                  </p>
                </div>
              </div>
            ) : null}
            <div className="option">
              <div className="option-line">
                <p className={`option-title`}>Adjustment</p>
                <p className={`option-value`}>
                  {this.props.lot.adjustWeight !== 0
                    ? `${this.props.lot.adjustWeight}%`
                    : ' - '}
                </p>
              </div>
            </div>
            {this.props.lot.adjustWeight !== 0 && (
              <div className="option">
                <div className="option-line">
                  <p className={`option-title`}>Curfew Weight</p>
                  <p className={`option-value`}>
                    {this.props.lot.publicDetails.weight.curfew} Kg
                  </p>
                </div>
              </div>
            )}
          </React.Fragment>,
        )
        break
      }
      case value.publicId === 'earliestDelivery': {
        const latestDelivery = findFieldByPublicId(details, 'latestDelivery')
        if (!latestDelivery || !this.canDisplay(latestDelivery)) {
          nodes.push(
            <div className="option" key="delivery-notice">
              <div className="option-line text-info">
                Buyers please allow an additional -1% for trucking every 200 kms
              </div>
            </div>,
          )
        }
        break
      }
      case value.publicId === 'latestDelivery': {
        nodes.push(
          <div className="option" key="delivery-notice">
            <div className="option-line text-info">
              Buyers please allow an additional -1% for trucking every 200 kms
            </div>
          </div>,
        )
        break
      }
      default:
        break
    }
    return nodes
  }

  renderField(value, index, details) {
    if (value.publicId === 'district') {
      return ''
    }
    let hasValue = ''
    if (typeof value.value !== 'boolean') {
      hasValue = value.value
    } else {
      hasValue = true
    }
    if (
      this.props.lot.optiweighAllowed &&
      value.publicId === 'assessmentDate'
    ) {
      return (
        <React.Fragment key={index}>
          {this.props.lot.mobEntryWeight ? (
            <div className="option">
              <div className="option-line">
                <p className={`option-title ${!hasValue ? 'empty' : ''}`}>
                  Mob Entry Weight
                </p>
                <p className={`option-value ${!hasValue ? 'empty' : ''}`}>
                  {parseFloat(this.props.lot.mobEntryWeight.toFixed(2))}
                </p>
              </div>
            </div>
          ) : null}
          {this.renderAdditionalData(value, details, true)}
        </React.Fragment>
      )
    }
    return (
      <React.Fragment key={index}>
        <div className="option">
          <div className="option-line">
            <p className={`option-title ${!hasValue ? 'empty' : ''}`}>
              {value.title}
            </p>
            <p className={`option-value ${!hasValue ? 'empty' : ''}`}>
              {this.convertValue(value)}
            </p>
          </div>
        </div>
        {this.renderAdditionalData(value, details)}
      </React.Fragment>
    )
  }

  splitForTwoColumns(arr) {
    let index = Math.round(arr.length / 2)
    let point = arr[index]
    let indexToSplit = arr.indexOf(point)
    let first = arr.slice(0, indexToSplit)
    let second = arr.slice(indexToSplit)
    return { first, second }
  }

  canDisplay(value) {
    if (value.value === 'NaN') {
      return false
    } else {
      return (
        this.props.allFields ||
        ((value.hasOwnProperty('isPublic') ? value.isPublic : true) &&
          (value.value ||
            (value.type === 'bool' && value.value === false) ||
            value.value === 0))
      )
    }
  }

  convertValue(value) {
    switch (value.type) {
      case 'number':
      case 'text':
      case 'textarea':
      case 'user':
      case 'dropdown':
      case 'weight':
      case 'rating':
      case 'picNumber':
      case 'tradingName':
        return value.value ? value.value : '-'
      case 'bool':
      case 'repeatForBool':
        return value.value ? 'Yes' : 'No'
      case 'date':
        return value.value
          ? moment(value.value).utc().format('DD MMM YYYY')
          : '-'
      case 'checkbox':
        return value.value ? 'Yes' : 'No'
      case 'phone':
        if (value.value) {
          let tel = value.value.indexOf('0') === 0 ? 'tel:' : 'tel:+'
          return (
            <a className="phone-link" href={`${tel}${value.value}`}>
              {value.value}
            </a>
          )
        } else {
          return '-'
        }
      case 'email':
        if (value.value) {
          return (
            <a className="email-link" href={`mailto:${value.value}`}>
              {value.value}
            </a>
          )
        } else {
          return '-'
        }
      case 'select':
        return value.value
          ? value.options.find((option) => option.value === value.value).label
          : '-'
      case 'multiDropdown':
        return value.value ? value.value.join('/') : '-'
      default:
        return value.value && value.value !== '0 Kg' ? value.value : '-'
    }
  }

  _renderHeadWeighedField(kindType, countWeighed) {
    return (
      <div className="fields-container">
        <div className="one-column">
          <div className="option">
            <div className="option-line">
              <p className="option-title">
                {kindType === 'sheep'
                  ? 'Head Weighed'
                  : 'Head Weighed / Weight Estimated'}
              </p>
              <p className="option-value">{countWeighed}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const { item, lot } = this.props
    let details
    switch (item.publicId) {
      case 'individualAssessment':
        return (
          <div className="row">
            <IndividualAssessment data={item.data} />
            {(lot.kindData.type === 'sheep' || lot.kindData.type === 'goat') &&
              lot.countWeighed > 0 &&
              this._renderHeadWeighedField(lot.kindData.type, lot.countWeighed)}
          </div>
        )
      case 'mobAverage':
        return (
          <div className="row">
            <OptiWeighAverageWeight data={item.data} />
            {(lot.kindData.type === 'sheep' || lot.kindData.type === 'goat') &&
              lot.countWeighed > 0 &&
              this._renderHeadWeighedField(lot.kindData.type, lot.countWeighed)}
          </div>
        )
      case 'individualAssessmentOptiweigh':
        return (
          <div className="row">
            <OptiWeighDistribution data={item.data} />
            {(lot.kindData.type === 'sheep' || lot.kindData.type === 'goat') &&
              lot.countWeighed > 0 &&
              this._renderHeadWeighedField(lot.kindData.type, lot.countWeighed)}
          </div>
        )
      default:
        details = this.renderDetails(item.values)
        break
    }
    return details.length ? (
      <div className="row">
        <div className="row-title-wrap">
          <p className="row-title">{item.title}</p>
        </div>
        <div className="fields-container">{details}</div>
      </div>
    ) : null
  }
}

ListItem.propTypes = {
  item: PropTypes.object.isRequired,
  allFields: PropTypes.bool,
}

export default DetailedInfo
