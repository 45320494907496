import React from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'
import moment from 'moment'
import './prewiev-image.css'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
  faTimes
} from '@fortawesome/fontawesome-free-solid'

import Carousel from '../Carousel/Carousel'
import { limitTextLength } from '../../utils/index'
import ImageLoader from '../ImageLoader/ImageLoader'
import '../Modal/prewiev-image.scss'

const PrewievModalImages = React.memo(({ closeModal, isOpen, lot, lotMedia, preview, numberOfSlides, indexImg }) => {
  if (lot !== null && lot && lot.media && lot.media.length > 0) {
    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        ariaHideApp={false}
        overlayClassName='overlayForPrewiev'
        portalClassName='ReactModalPortal-Email'
      >
        <span className='icon-close icon-danger icon-for-prewiev' onClick={closeModal}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
        <div className='preview-carousel'>
          <Carousel
            key={lot._id}
            preview={preview}
            numberOfSlides={numberOfSlides}
            carouselClassName={preview ? 'preview' : null}
            currentSlide={indexImg}
          >
            {lotMedia.map((file, index) => {
              switch (true) {
                case /image/.test(file.mimeType):
                  return (
                    <div className='carousel-item' key={file.key}>
                      <ImageLoader className='carousel-img' src={file.url} alt='' />
                      {file.timestamp && !preview && <span className='date'>{moment(file.timestamp).format('DD/MM/YYYY')}</span>}
                    </div>
                  )
                case /video/.test(file.mimeType):
                  return (
                    <div className={file.title ? 'carousel-item with-title' : 'carousel-item'} key={index}>
                      {preview
                        ? (
                          <img className='carousel-img video-thumbnail' src={file.thumbnail} alt='' />
                        )
                        : (
                          <video className={file.title ? 'carousel-video with-title' : 'carousel-video video-prewiev'} poster={file.thumbnail} controls>
                            <source src={file.url} type={file.type} />
                          </video>
                        )}
                      {file.title && (<div className='video-title'>{limitTextLength(file.title, 60)}</div>)}
                      {file.timestamp && !preview && <span className='date'>{moment(file.timestamp).format('DD/MM/YYYY')}</span>}
                    </div>
                  )
                default:
                  throw new Error(`Unknown mime type: ${file.mimeType}`)
              }
            }) }
          </Carousel>
        </div>
      </Modal>
    )
  }
})

PrewievModalImages.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  lot: PropTypes.object,
  preview: PropTypes.bool,
  numberOfSlides: PropTypes.number
}

export default PrewievModalImages
