import update from 'immutability-helper'

import { CHANGE_STATE_PROP, SHOW_SPINNER,
  SHOW_MODAL, HIDE_MODAL } from '../constants/index'

const REDUCER = 'TEMP'

const initialState = {
  hasData: false,
  spinner: false,
  loadings: 0,
  modalActive: false,
  modalData: {
    title: 'FarmGate',
    message: ''
  },
  onLotForm: false,
  saveAndLogout: false,
  biddingSwapLots: [],
  isSound: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case REDUCER + CHANGE_STATE_PROP:
      return update(state, {
        [action.state.prop]: { $set: action.state.value }
      })
    case SHOW_SPINNER:
      let loadings
      if (action.bool) {
        loadings = state.loadings + 1
      } else {
        loadings = state.loadings - 1
      }
      if (loadings < 1) {
        return update(state, {
          spinner: { $set: false },
          loadings: { $set: 0 }
        })
      } else {
        return update(state, {
          spinner: { $set: true },
          loadings: { $set: loadings }
        })
      }
    case SHOW_MODAL:
      return update(state, {
        modalActive: { $set: true },
        modalData: { $set: action.data }
      })
    case HIDE_MODAL:
      return update(state, {
        modalActive: { $set: false },
        modalData: { $set: {} }
      })
    default:
      return state
  }
}
